import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { AboutPage } from './pages/AboutPage'
import { BsmtvPage } from './pages/BsmtvPage'
import { BioPage } from './pages/BioPage'
import { SocialPage } from './pages/SocialPage'
import { PrivateClubPage } from './pages/PrivateClubPage'
import { ContactPage } from './pages/ContactPage'
import VideosPage from './pages/VideosPage'
import LoginComponent from './components/User/LoginComponent'
import ChangePassPage from './pages/shop/ChangePassPage'
import AccountPage from './pages/shop/AccountPage'
import PlayerPage from './pages/player/PlayerPage'
import CheckoutPage from './pages/shop/CheckoutPage'
import MentionsPage from './pages/MentionsPage'


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="jeremy-watier" element={<AboutPage />} />
          <Route path="a-propos-de-bsmtv" element={<BsmtvPage />} />
          <Route path="jeremy-watier-biographie" element={<BioPage />} />
          <Route path="reseaux-sociaux-presse" element={<SocialPage />} />
          <Route path="rejoindre-le-club-prive-de-lsda" element={<PrivateClubPage />} />
          <Route path="contacter-bsmtv" element={<ContactPage />} />
          <Route path="mes-programmes-videos" element={<VideosPage />} />
          <Route path='my-account' element={<AccountPage />} />
          <Route path='change-password' element={<ChangePassPage />} />
          <Route path='video-player' element={<PlayerPage />} />
          <Route path='checkout' element={<CheckoutPage />} />
          <Route path="mentions-legales" element={<MentionsPage />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
      </BrowserRouter>
      <LoginComponent />
    </div>
  );
}

export default App;
