//import React from 'react'
import React, { useState } from 'react';
import { sendContactForm } from '../../shared/dataServices';
import Swal from 'sweetalert2'

export function ContactFormComponent() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        setSuccess(false);
        let body = JSON.stringify(formData)
        

        

        const response = await sendContactForm(body)
            .then((data) => {
                setLoading(false)
                setError(false)
                setSuccess(true)
                Swal.fire({
                    title: 'Message envoyé',
                    icon: 'success',
                    text: 'Votre message a été envoyé avec succès, nous vous contacterons bientôt'
                })
                return data
            }).catch((error) => {
                Swal.fire({
                    title: 'Votre message na pas été envoyé',
                    icon: 'error',
                    text: 'nous avons eu un problème ' + error
                })
                console.log(error)
           })

           console.log(response)
    };

    return (
        <div className="contact-form">
            {success && (
                <div className="contact-form-success alert alert-success mt-4">
                    <strong>Success!</strong> Your message has been sent to us.
                </div>
            )}
            {error && (
                <div className="contact-form-error alert alert-danger mt-4">
                    <strong>Error!</strong> There was an error sending your message.
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col">
                        <label className="form-label mb-1 text-2">Votre nom ( obligatoire )</label>
                        <input
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            data-msg-required="Please enter your name."
                            maxLength="100"
                            className="form-control text-3 h-auto py-2"
                            name="name"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label className="form-label mb-1 text-2">E-mail</label>
                        <input
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            data-msg-required="Please enter your email."
                            maxLength="100"
                            className="form-control text-3 h-auto py-2"
                            name="email"
                            required
                        />
                    </div>
                    <div className="form-group col">
                        <label className="form-label mb-1 text-2">Objet*</label>
                        <input
                            type="text"
                            value={formData.subject}
                            onChange={handleChange}
                            data-msg-required="Please enter the subject."
                            maxLength="100"
                            className="form-control text-3 h-auto py-2"
                            name="subject"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label className="form-label mb-1 text-2">Message*</label>
                        <textarea
                            value={formData.message}
                            onChange={handleChange}
                            maxLength="5000"
                            data-msg-required="Please enter your message."
                            rows="8"
                            className="form-control text-3 h-auto py-2"
                            name="message"
                            required
                        ></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <button type="submit" className="btn btn-primary btn-modern" disabled={loading}>
                            {loading ? 'Loading...' : 'Envoyer'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactFormComponent;

