import React from 'react'

export function OurLifeComponent() {
  return (
    <div>
        <div className="container">
            <div className=" text-center mt-4">
                <h1 className="font-weight-bold text-8 text-danger">
                    Notre vie et celle que l’on crée
                </h1>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="owl-carousel owl-theme full-width dots-inside float-start" data-plugin-options="{'items': 1, 'loop': true, 'nav': false, 'dots': true, 'autoplay': true, 'autoplayTimeout': 3000}">
                        <div>
                            <img src="/assets/img/jeremy/jeremy05.jpg" className="img-fluid" alt="" />
                            
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/jeremy06.jpg" className="img-fluid" alt="" />
                            
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/jeremy07.jpg" className="img-fluid" alt="" />
                            
                        </div>
				    </div>
                </div>
                <div className="col-md-8">
                    <p>
                        Dans ma vision du monde, je crois profondément que la vie que nous menons est celle que nous créons.
                    </p>
                    <p>
                        Chaque choix, chaque action que nous faisons façonne notre réalité et détermine le cours de notre existence. C'est une conviction que j'ai développée grâce à mon exploration du mindset, de la spiritualité, ainsi que de mes expériences personnelles, notamment mon initiation au sein de certaines sociétés secrètes.
                    </p>
                    <p>
                        À travers mes voyages dans ces différents domaines, j'ai réalisé que nous sommes les architectes de notre propre destinée. Nos pensées, nos croyances et nos actions sont les fondations sur lesquelles nous construisons notre vie. Comprendre cela nous donne un pouvoir immense - le pouvoir de façonner consciemment notre réalité selon nos désirs les plus profonds.
                    </p>
                    <p>
                        Cette philosophie de création de sa propre vie est une source d'inspiration constante pour moi. Elle m'encourage à prendre la responsabilité de mes choix, à cultiver des pensées positives et à poursuivre mes rêves avec détermination. Je crois fermement que chaque individu a le pouvoir de créer une vie qui lui est propre, remplie de bonheur, de succès et de sens.
                    </p>
                    <p>
                        En partageant cette perspective avec les autres, j'espère inspirer et encourager ceux qui cherchent à prendre le contrôle de leur propre destinée. Car je crois fermement que lorsque nous comprenons que la vie est celle que nous créons, nous pouvons commencer à créer la vie dont nous rêvons vraiment.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
