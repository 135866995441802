import React from 'react'

export default function VideoBussinessComponent() {
    return (
        <div>

            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                <iframe title='Video Mindset' src="https://iframe.mediadelivery.net/embed/256628/cbe0ede9-c747-4e75-a191-4b6bb998d4d0?autoplay=false&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style={{ border: 0, position: 'absolute', top: 0, height: '100%', width: '100%' }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
            </div>

            <h4 className='fw-bold'>
                <i className="fa fa-tv"></i> Description du Produit :
            </h4>

            <ul className='list list-icons list-primary ps-4'>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="0">
                    <i className="fas fa-check"></i> 1.  Vivre d’un business en ligne :
                    <p>
                        Apprenez à créer et à développer un business en ligne rentable qui vous permettra de générer des revenus durables. Découvrez les étapes essentielles pour transformer une idée en une entreprise prospère, en utilisant des stratégies éprouvées pour maximiser vos profits.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <i className="fas fa-check"></i> 2. Devenir entrepreneur :
                    <p>
                        Cette formation vous guidera dans votre parcours entrepreneurial, en vous fournissant les compétences nécessaires pour lancer et gérer votre propre entreprise. Du développement de votre vision à la mise en place de votre plan d'affaires, chaque module est conçu pour vous aider à devenir un entrepreneur accompli.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="600">
                    <i className="fas fa-check"></i> 3. Formation express info produit :
                    <p>
                        Créez des infoproduits de qualité en un temps record. Apprenez à identifier les besoins de votre marché, à structurer votre contenu de manière efficace, et à produire des supports éducatifs qui se vendent.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="900">
                    <i className="fas fa-check"></i> 4. Formation express personal branding :
                    <p>
                        Développez votre marque personnelle pour vous démarquer dans votre niche. Apprenez à construire une image forte et cohérente, à utiliser les réseaux sociaux de manière stratégique et à établir votre autorité en ligne.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="1200">
                    <i className="fas fa-check"></i> 5. Formation SEO :
                    <p>
                        Maîtrisez les bases du SEO pour augmenter la visibilité de votre site web sur les moteurs de recherche. Découvrez les meilleures pratiques pour optimiser votre contenu et attirer un trafic qualifié.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="1500">
                    <i className="fas fa-check"></i> 6. Formation funnel :
                    <p>
                        Créez des tunnels de vente performants qui convertissent les visiteurs en clients. Apprenez à structurer vos offres, à segmenter votre audience et à automatiser votre processus de vente.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="1800">
                    <i className="fas fa-check"></i> 7. Formation emailing :
                    <p>
                        Optimisez vos campagnes d'emailing pour maximiser l'engagement et les conversions. Apprenez à construire une liste de contacts qualifiés, à rédiger des emails persuasifs et à analyser les performances de vos envois.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="2100">
                    <i className="fas fa-check"></i> 8. Résumé de A à Z :
                    <p>
                        Bénéficiez d'un aperçu complet et structuré de toutes les étapes nécessaires pour réussir dans le business en ligne. Ce module récapitule les points clés et les meilleures pratiques abordées dans la formation, vous offrant une feuille de route claire pour votre succès.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="2400">
                    <i className="fas fa-check"></i> 9. Et toi pourquoi tu ne le fais pas ?
                    <p>
                        Posez-vous la question et prenez le contrôle de votre avenir. Cette formation est conçue pour vous donner les clés et la motivation nécessaires pour passer à l’action. Ne laissez plus vos doutes vous retenir.
                    </p>
                </li>
            </ul>
        </div>
    )
}
