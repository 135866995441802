import React from 'react'

export function PrivateClubComponent() {
  return (
    <div>
      <div className="bg-color-light-scale-1">
        <div className="container">
          <div className=" text-center pt-5">
            <h1 className="font-weight-bold text-8 text-danger">
              QU’EST-CE QUE LE CLUB PRIVÉ DE #BMTV?
            </h1>
            <div className="row">
              <div className="col-md-8 text-justify">
                <h2>🤬 #CENSURE… </h2>
                <p>
                  Tu n’es certainement pas sans savoir qu’il devient de plus en plus difficile de s’exprimer librement et ce qu’il s’agisse de YouTube des réseaux sociaux ( 👉liens social presse ⚠️ ) ou de manière plus générale « publiquement parlant ».
                </p>
                <p>
                  Le Club BMTV est un espace dédié aux passionnés de business et de mindset. Que vous soyez un entrepreneur en herbe, un professionnel chevronné ou simplement quelqu'un qui aspire à améliorer sa vie, ce club est fait pour vous !
                </p>
                <p>
                  Pour cela, je vous ai créé un compte Telegram, ou la discussion est ouvert à tous et où je ferai également part des actualités concernant #BMTV
                </p>

              </div>
              <div className="col-md-4">
                <img className="img-fluid pb-3" src="./assets/img/office/pivate_club.jpg" alt="" />
                <img className="img-fluid mt-3" src="./assets/img/office/arrow.jpg" alt="" />
                <div className="bg-color-dark p-4">
                  <p className="text-center text-white">CLUB PRIVÉ OFFERT</p>
                  <h2 className="text-center text-white">Offert</h2>
                  <div className="text-center">
                    <a href="https://t.me/+NAqcIObpNjxiYTNk" className="btn btn-primary btn-lg">Rejoindre le Club Privé</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
