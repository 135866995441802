import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { FooterComponent } from '../components/FooterComponent'
import { AboutHeaderComponent } from '../components/About/AboutHeaderComponent/AboutHeaderComponent'
import { WhoIsComponent } from '../components/About/WhoisComponent/WhoIsComponent'
import { AnecdotesComponent } from '../components/About/AnecdotesComponent/AnecdotesComponent'
import { OurLifeComponent } from '../components/About/OurLifeComponent/OurLifeComponent'
import { ExcuseComponent } from '../components/About/ExcuseComponent/ExcuseComponent'


export function AboutPage() {
  return (
    <div>
    <HeaderComponent />
      <div role="main" class="main">
        <AboutHeaderComponent />
        <WhoIsComponent />
        <AnecdotesComponent />
        <OurLifeComponent />
        <ExcuseComponent />
        <FooterComponent />
      </div>
    </div>
  )
}
