import React from 'react'

export function BioComponent() {
    return (
        <div className="container mt-5">
            <p>
                Bonjour, je suis quelqu'un qui a connu de grands succès, tant sur le plan personnel que intellectuel, malgré quelques revers temporaires dans le passé.
            </p>
            <p>
                Depuis mon plus jeune âge, j'ai entrepris de construire un véritable empire dans le domaine des affaires et du développement personnel. J'ai consacré beaucoup d'efforts à mon propre développement, en explorant également des voies spirituelles peu communes. La science, notamment la physique quantique et la chimie, m'a toujours fasciné dès mon plus jeune âge.
            </p>
            <p>
                Mon intérêt pour la réussite m'a poussé à me tourner vers l'e-commerce. Après avoir investi dans de nombreuses formations coûteuses, je suis convaincu d'avoir acquis les compétences nécessaires pour offrir des vidéos de qualité à un prix raisonnable. Je me décris comme humble, dévoué et exceptionnel.
            </p>
            <div className="row">
                <div className="col-md-9">
                    <div className="bg-primary rounded-4 text-color-light py-5 px-3">
                        <div className="container">
                            <h2 className="font-weight-bold text-8 text-light"><i className="fa fa-people-arrows"></i> Mes mentors</h2>
                            J'ai eu de nombreux mentors, que ce soit en business ou en mindset. Dans le e-commerce, je peux notamment citer Romain SEO, Tugan Bara (Julien), Clément Perrin, et bien d'autres. Et dans le domaine du mindset, je peux citer David Bertrand, sadhguru, et bien d’autres. J'ai également beaucoup appris grâce à des livres, des audios, et même la musique de rap, qui m'ont ouvert l'esprit.
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="owl-carousel owl-theme full-width dots-inside float-start" data-plugin-options="{'items': 1, 'loop': true, 'nav': false, 'dots': true, 'autoplay': true, 'autoplayTimeout': 3000}">
                        <div>
                            <img src="/assets/img/jeremy/bio_slide1.jpg" className="img-fluid" alt="" />

                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide2.jpg" className="img-fluid" alt="" />

                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide3.jpg" className="img-fluid" alt="" />

                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide4.jpg" className="img-fluid" alt="" />

                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide5.jpg" className="img-fluid" alt="" />

                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide6.jpg" className="img-fluid" alt="" />

                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide7.jpg" className="img-fluid" alt="" />
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide8.jpg" className="img-fluid" alt="" />
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/bio_slide9.jpg" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
