import React from 'react'

export default function AccountAside() {
    return (
        <div>
            <div className="d-flex justify-content-center mb-4">
                <div className="profile-image-outer-container">
                    <div className="profile-image-inner-container bg-color-primary">
                        <img src='./assets/img/icons/avatar_male.png' alt='' />
                    </div>
                </div>
            </div>

            <aside className="sidebar mt-2" id="sidebar">
                <ul className="nav nav-list flex-column mb-5">
                    <li className="nav-item"><a href='/' className="nav-link" >Mes données</a></li>
                    <li className="nav-item"><a href='/' className="nav-link">Changer le mot de passe</a></li>
                    <li className="nav-item"><a href='/' className="nav-link">Retourner</a></li>
                </ul>
            </aside>
        </div>
    )
}
