//import React from 'react'
import React, { useEffect } from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { VideoHeaderComponent } from '../components/Videos/VideoHeaderComponent'
import VideosComponent from '../components/Videos/VideosComponent'
import { FooterComponent } from '../components/FooterComponent'
import { useUserData } from '../shared/UserDataContext'
import { useCart } from '../shared/CartDataContext'

import Swal from 'sweetalert2'
import { getPaymentStatus, send_mail_payment, setUserPlan } from '../shared/dataServices'


export default function VideosPage() {
  const { readToken } = useUserData();
  const token = readToken();
  const { clearCart } = useCart();

  const params = new URLSearchParams(window.location.search);
  const success = params.get('success');
  const canceled = params.get('canceled');

  useEffect(() => {

    if (success === 'true') {
      if (sessionStorage.getItem('productName') === null) {
        return
      }
      let body = {
        "_userId": token.nameid,
        "_plan": sessionStorage.getItem('productName'),
      }
      setUserPlan(body).then(async () => {
        let userData = JSON.stringify({ email: token.email })
        console.log(userData);

        await send_mail_payment(userData);
        handleStripeResponse(true);
      }).catch((error) => {
        console.log(error);
      });

    } else if (canceled === 'true') {
      handleStripeResponse(false);
    }
  }, []);


  const handleStripeResponse = async (successStripe) => {
    if (successStripe) {
      Swal.fire({
        title: 'Paiement réussi',
        icon: 'success',
        text: 'Votre paiement a été traité avec succès. Bienvenue sur BizmindTV...',
        confirmButtonText: 'Accéder au contenu'
      }).then(() => {
        clearCart()
        sessionStorage.removeItem('productName');
        window.location.href = '/video-player';
      }).catch((error) => {
        console.log(error);
      })
    }
    else {
      Swal.fire({
        title: 'Paiement échoué',
        icon: 'error',
        text: 'Le paiement n\'a pas été effectué avec succès. Veuillez réessayer plus tard.'
      });
    }
  };

  return (
    <div>
      <HeaderComponent />
      <div role='main' className="main">
        <VideoHeaderComponent />
        <VideosComponent />
      </div>
      <FooterComponent />
    </div>
  )


}
