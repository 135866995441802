import React from 'react'

export function ExcuseComponent() {
    return (
        <div className="bg-color-light-scale-1 mt-4">
            <div className="container py-4">
                <div className=" text-center mt-4">
                    <h1 className="font-weight-bold">
                        Et toi quel est ton excuse ?
                    </h1>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            Dès mes premiers pas dans le monde du business et du mindset, j’ai été captivé par la puissance de la création. Je crois fermement que chaque individu a le pouvoir de façonner sa propre réalité, que ce soit dans le domaine entrepreneurial ou dans sa vie personnelle.
                        </p>
                        <p>
                            Pourtant, malgré cette conviction profonde, je me suis parfois retrouvé confronté à une excuse bien connue : la peur. La peur de l’échec, de l’inconnu, ou même de mon propre potentiel. Ces craintes peuvent parfois m’empêcher de saisir pleinement les opportunités qui se présentent à moi, que ce soit dans mes entreprises ou dans mes aspirations personnelles.
                        </p>
                        <p>
                            À certains moments, j’ai laissé le doute et l’incertitude prendre le dessus, me privant ainsi de la possibilité de créer une vie et une entreprise qui reflètent véritablement mes aspirations et mes valeurs. Pourtant, je suis conscient que ces excuses ne sont que des obstacles temporaires sur le chemin de la réalisation de mes rêves d’entrepreneur et de développement personnel.
                        </p>
                        <p>
                            Je choisis donc de faire face à mes peurs et de les surmonter avec courage et détermination. Je m’engage à croire en ma capacité à réussir dans mes entreprises, tout en continuant à approfondir ma compréhension du mindset et à cultiver une mentalité de croissance.
                        </p>
                        <p>
                            Car je sais que chaque pas que je fais, chaque choix que je fais, me rapproche un peu plus de la vie et de l’entreprise que je désire créer. Je refuse de laisser la peur dicter ma destinée. Au contraire, je choisis de prendre les rênes de ma vie et de tracer mon propre chemin vers le succès et l’épanouissement.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <a href='/jeremy-watier-biographie' className="btn btn-gradient btn-xl">VOIR LA BIOGRAPHIE COMPLÈTE DE JEREMY WATIER</a>
                    </div>
                    <div className="col-md-6">
                        <a href='/mes-programmes-videos' className="btn btn-gradient btn-xl">DÉCOUVRIR TOUS NOS PROGRAMMES VIDÉOS</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
