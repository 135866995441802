import React from 'react'
import { globalConstants } from '../../shared/globalConstants'

export function SocialComponent() {
    return (
        <div className="bg-color-light-scale-1">
            <div className="container">
                <div className=" text-center pt-5">
                    <h1 className="font-weight-bold text-8 text-danger">
                        RÉSEAUX SOCIAUX, PRESSE ET MÉDIAS
                    </h1>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>
                                🚀 La fusée #BMTV a été lancée officiellement en direction de la Terre et ouverte à toutes les personnes motivées « toute catégorie confondue » :
                            </p>
                            <div className="row">
                                <div className="col-md-12">
                                    <a href={globalConstants.socialLinks.youtube} className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-youtube"></i> YouTube
                                    </a>

                                    <a href={globalConstants.socialLinks.facebook} className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-facebook-f"></i> Facebook
                                    </a>

                                    <a href={globalConstants.socialLinks.instagram} className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-instagram"></i> Instagram
                                    </a>

                                    <a href={globalConstants.socialLinks.twitter} className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-twitter"></i> Twitter
                                    </a>

                                    <a href={globalConstants.socialLinks.linkedin} className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-linkedin"></i> LinkedIn
                                    </a>

                                    <a href={globalConstants.socialLinks.tiktok} className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-tiktok"></i> TikTok
                                    </a>

                                    {/* <a href="https://www.pinterest.fr/bizmindtv/" className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fab fa-pinterest"></i> Pinterest
                                    </a> */}

                                </div>
                            </div>
                            <div className="mt-4">
                                <p>
                                    Jeremy Watier est bien plus qu'un entrepreneur c'est un expert du e commerce un penseur spirituel.
                                </p>
                                <p>
                                    Depuis ses débuts dans le monde du business en ligne à l'âge de 16 ans, jeremy a parcouru un chemin remarquable, explorant les profondeurs de la réussite et du développement personnel.
                                </p>
                            </div>
                            <div className="mt-4">
                                <span className="text-4 font-weight-bold"> Pourquoi le suivre? </span>
                                <p>
                                    vous aurez accès à une mine d'informations précieuses pour booster votre entreprise et améliorer votre mindset.
                                </p>
                                <p>
                                    Ses vidéos YouTube dynamiques et inspirantes vous guideront à travers les rouages du e-commerce, vous offrant des conseils pratiques et des stratégies éprouvées pour réussir dans le monde des affaires.
                                </p>
                                <p>
                                    Mais ce n'est pas tout Jeremy partage également ses réflexions profondes sur le mindset, et la croissance personnelle. Vous trouverez dans ses publications une source d'inspiration et de motivation pour surmonter les obstacles, réaliser vos objectifs et créer la vie que vous désirez vraiment.
                                </p>
                            </div>
                            <div className="mt-4">
                                <div>
                                    <a href="/mes-programmes-videos" className="btn btn-lg btn-block btn-gradient btn-facebook btn-icon me-2 mb-2">
                                        <i className="fa fa-tv"></i> Programme BizMind
                                    </a>
                                </div>
                                <p>
                                    🎙 Veuillez noter que Jeremy est toujours très heureux de répondre à toutes vos demandes de partenariats, d’interviews aussi être invité dans certains collèges et lycées ainsi que dans tout autre contexte visant à diffuser son message de paix, d’abondance à quiconque voudra bien l’entendre.
                                </p>
                                <p>
                                    Pour tout demande relative à une éventuelle future collaboration, merci de nous <a href="/contacter-bsmtv">contacter</a> sans plus attendre.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
