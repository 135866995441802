import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { loginUser, registerUser, send_mail_password_reset } from '../../shared/dataServices'
import { useUserData } from '../../shared/UserDataContext'


export default function LoginComponent() {
    const { setLoggedIn } = useUserData()
    const [resetPass, setResetPass] = useState(false)
    const [emailReset, setEmailReset] = useState('')
    const [loginData, setLoginData] = useState({
        user_: '',
        password_: ''
    })

    const [userData, setUserData] = useState({
        user_name_: '',
        user_: '',
        password_: '',
        role_: 'User',
        state: 'Active'
    })

    const [cPassword_, setCPassword_] = useState('')
    // const [showPass, setShowPass] = useState(false)

    // const showPassword = () => {
    //     setShowPass(!showPass)
    // }

    const isEmail = (email) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }




    const signUpForm = (mobile) => {
        //console.log('singUp');
        const box = document.getElementById('frontBox')
        const boxLogin = document.getElementById('loginForm')
        const boxReg = document.getElementById('regForm')
        if (!mobile) {
            box?.classList.add('moving')
        }
        boxLogin?.classList.add('hide')
        boxReg?.classList.remove('hide')
    }

    const loginForm = (mobile) => {
        //console.log('login');
        const box = document.getElementById('frontBox')
        const boxLogin = document.getElementById('loginForm')
        const boxReg = document.getElementById('regForm')
        if (!mobile) {
            box?.classList.remove('moving')
        }
        boxLogin?.classList.remove('hide')
        boxReg?.classList.add('hide')
    }

    const login = () => {

        if (loginData.user_ === '' || loginData.password_ === '') {
            Swal.fire('échec de connexion', 'Veuillez remplir les champs obligatoires', 'error')
            return;
        }

        loginUser(loginData).then(
            res => {
                let resp = res;
                console.log(res);
                localStorage.setItem("AuthToken", resp.AuthToken)
                setLoggedIn(true)
                window.location.reload()
            }, err => {
                console.log(err)
                Swal.fire('échec de connexion', err, 'error')
            }
        )

    }

    const register = () => {

        if (userData.user_name_ === '' || userData.user_ === '' || userData.password_ === '') {
            Swal.fire("Erreur lors de l'enregistrement de l'utilisateur", 'Veuillez remplir les champs obligatoires', 'error')
            return;
        }

        if (userData.user_name_.length < 6) {
            Swal.fire("Erreur lors de l'enregistrement de l'utilisateur", 'Le nom d\'utilisateur doit avoir au moins 6 caractères', 'error')
            return;
        }

        if (userData.password_.length < 8) {
            Swal.fire("Erreur lors de l'enregistrement de l'utilisateur", 'Le mot de passe doit avoir au moins 8 caractères', 'error')
            return;
        }

        if (userData.password_ !== cPassword_) {
            Swal.fire("Erreur lors de l'enregistrement de l'utilisateur", 'Les mots de passe ne correspondent pas', 'error')
            return;
        }

        if (!isEmail(userData.user_)) {
            Swal.fire("Erreur lors de l'enregistrement de l'utilisateur", 'Veuillez saisir un email valide', 'error')
            return;
        }


        registerUser(userData).then(
            res => {
                Swal.fire({
                    title: 'Accueillir',
                    text: 'Utilisateur enregistré avec succès',
                    icon: 'success',
                    confirmButtonText: 'Accepter'
                }).then(() => {
                    window.location.reload()
                })
            }, err => {
                console.log(err)
                Swal.fire("Erreur lors de l'enregistrement de l'utilisateur", 'Cet email est déjà enregistré, si vous avez oublié votre mot de passe vous pouvez le récupérer en cliquant <a href="reset-pass">ici</a>', 'error')
            }
        )
    }

    const sendResetPass = () => {
        if (emailReset === '') {
            Swal.fire('Erreur lors de la récupération du mot de passe', 'Veuillez saisir votre email', 'error')
            return;
        }

        if (!isEmail(emailReset)) {
            Swal.fire('Erreur lors de la récupération du mot de passe', 'Veuillez saisir un email valide', 'error')
            return;
        }

        const body = {
            user_: emailReset
        }

        send_mail_password_reset(JSON.stringify(body)).then(
            res => {
                Swal.fire({
                    title: 'Récupération du mot de passe',
                    text: 'Un email a été envoyé avec les instructions pour réinitialiser votre mot de passe',
                    icon: 'success',
                    confirmButtonText: 'Accepter'
                }).then(() => {
                    window.location.reload()
                })
            }, err => {
                console.log(err)
                Swal.fire('Erreur lors de la récupération du mot de passe', 'Cet email n\'est pas enregistré', 'error')
            }
        )
    }

    const handleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeRegister = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    const handleChangePassword = (e) => {
        setEmailReset(e.target.value)
    }



    return (

        <div className="modal fade" tabIndex="-1" role="dialog" id="loginModal">
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body">
                        <div className="container-login">
                            <div className="backbox bg-color-grey-scale-2 rounded d-sm-flex d-none">
                                <div className="loginMsg">
                                    <div className="textcontent">
                                        <i className="fa-solid fa-user-plus fa-2x mb-2 text-color-dark"></i>
                                        <p className="title font-weight-bold">Vous n'avez pas de compte?</p>
                                        <p>Inscrivez-vous pour continuer votre achat.</p>
                                        <button className="btn btn-rounded btn-info box-shadow-2" onClick={() => signUpForm()} >
                                            S'inscrire
                                        </button>
                                    </div>
                                </div>
                                <div className="signupMsg">
                                    <div className="textcontent">
                                        <i className="fa-solid fa-user-check fa-2x mb-2 text-color-dark"></i>
                                        <p className="title font-weight-bold">Avez-vous déjà un compte?</p>
                                        <p>Connectez-vous pour continuer votre achat</p>
                                        <button className="btn btn-rounded btn-info box-shadow-2 float-right" onClick={() => loginForm()}>
                                            Entrer dans
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* backbox */}

                            <div id="frontBox" className="frontbox border-0 box-shadow-2">

                                {
                                    resetPass ?
                                        <div id="resetForm" className="login">
                                            <div className="font-weight-bold text-color-primary text-6">Récupération du mot de passe</div>
                                            <div className="small my-2">Entrez l'e-mail enregistré pour réinitialiser votre mot de passe</div>
                                            <div className="inputbox my-4">
                                                <input
                                                    type="text"
                                                    name="user_reset"
                                                    placeholder="Email"
                                                    value={emailReset}
                                                    onChange={(e) => handleChangePassword(e)}
                                                />
                                            </div>
                                            <button className="btn btn-link text-2" onClick={() => setResetPass(false)}>Retour à</button>
                                            <div>
                                                <button className="btn btn-block btn-success mt-4" onClick={() => sendResetPass()}>
                                                    <i className="fa-solid fa-right-to-bracket me-2"></i>Envoyer
                                                </button>
                                            </div>
                                        </div>

                                        :
                                        <div id="loginForm" className="login">
                                            <div className="font-weight-bold text-color-primary text-6">Commencer la session</div>
                                            <div className="small my-2">Connectez-vous avec vos identifiants</div>
                                            <div className="inputbox">
                                                <input
                                                    type="text"
                                                    name="user_"
                                                    placeholder="Email"
                                                    value={loginData.user_}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <input
                                                    type="password"
                                                    name="password_"
                                                    placeholder="Mot de passe"
                                                    value={loginData.password_}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            <button className="btn btn-link text-2" onClick={() => setResetPass(true)}>Vous avez oublié votre mot de passe?</button>
                                            <div>
                                                <button className="btn btn-block btn-success mt-4" onClick={() => login()}>
                                                    <i className="fa-solid fa-right-to-bracket me-2"></i>Entrer dans
                                                </button>
                                            </div>
                                            <div className='d-block d-sm-none mt-2'>
                                                <button className="btn btn-link" onClick={() => signUpForm(true)} >
                                                    S'inscrire
                                                </button>
                                            </div>
                                        </div>
                                }


                                <div id="regForm" className="signup hide">
                                    <div className="font-weight-bold text-color-primary text-6">S'INSCRIRE</div>
                                    <div className="small my-2">Complétez ces informations et appuyez sur envoyer</div>
                                    <div className="inputbox">
                                        <input
                                            type="text"
                                            name="user_name_"
                                            placeholder="Des noms"
                                            value={userData.user_name_}
                                            onChange={(e) => handleChangeRegister(e)}
                                        />
                                        <input
                                            type="text"
                                            name="user_"
                                            placeholder="Email"
                                            value={userData.user_}
                                            onChange={(e) => handleChangeRegister(e)}
                                        />
                                        <div className="row">
                                            <div className="col-lg-6" style={{ paddingRight: 5 }}>
                                                <input
                                                    type="password"
                                                    name="password_"
                                                    placeholder="Mot de passe"
                                                    value={userData.password_}
                                                    onChange={(e) => handleChangeRegister(e)}
                                                />
                                            </div>
                                            <div className="col-lg-6" style={{ paddingLeft: 5 }}>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Répéter le mot de passe"
                                                    value={cPassword_}
                                                    onChange={(e) => setCPassword_(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-block btn-success mt-4" onClick={() => register()}>
                                            <i className="fa-solid fa-right-to-bracket mr-1"></i> Envoyer
                                        </button>
                                    </div>
                                    <div className='d-block d-sm-none'>
                                        <button className="btn btn-link" onClick={() => loginForm(true)}>
                                            Entrer dans
                                        </button>
                                    </div>
                                </div>

                            </div>
                            {/* frontbox */}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
