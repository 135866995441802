import React from 'react'
const styles = {
    backgroundAbout: {
        backgroundImage: 'url(assets/img/banners/videoBanner.jpg)',
        backgroundSize: 'cover',
    }
}



export function VideoHeaderComponent() {
    return (
        <section style={styles.backgroundAbout} className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-quaternary overlay-show overlay-op-8 mb-0" >
            <div className="container pt-5">
                <div className="row">
                    <div className="col align-self-center p-static text-center mt-4">

                    </div>
                </div>
            </div>
        </section>
    )
}