import React from 'react'

export function WhoIsComponent() {
  return (
    <div>
        <div className="container text-center mt-4">
            <h2 className="font-weight-bold text-8 text-danger">Qui est Jeremy ?</h2>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <p>
                        Jérémy incarne l'esprit de détermination et de courage. Depuis un jeune âge, son intérêt pour le monde du business en ligne et du développement personnel, son 'mindset', l'a poussé à poursuivre son rêve avec une détermination hors normes. Fort de son ambition de partager ses connaissances et d'aider les autres à mener une vie épanouissante, il a fondé sa société avec une vision claire en tête.
                    </p>
                    <p>
                        Dès l'âge de 16 ans, Jérémy a commencé à explorer le domaine du e-commerce, s'imprégnant de chaque aspect avec une curiosité insatiable. Cette longue expérience lui confère une crédibilité incontestable pour transmettre ses enseignements à ceux qui cherchent à suivre ses pas. Son désir profond d'accessibilité se traduit par des explications simples et limpides, rendant son contenu compréhensible pour tous.
                    </p>
                    <p>
                        À travers sa société, Jérémy aspire à offrir bien plus qu'un simple accès à l'information. Il veut être un guide pour ceux qui cherchent à transformer leurs vies grâce à l'utilisation de la technologie et du développement personnel. Son engagement envers le bien-être des autres est au cœur de chaque initiative qu'il entreprend, reflétant ainsi sa passion pour aider les autres à réaliser leur plein potentiel.
                    </p>
                </div>
                <div className="col-md-4">
                    <div className="owl-carousel owl-theme full-width dots-inside float-start" data-plugin-options="{'items': 1, 'loop': true, 'nav': false, 'dots': true, 'autoplay': true, 'autoplayTimeout': 3000}">
                        <div>
                            <img src="/assets/img/jeremy/jeremy01.jpg" className="img-fluid" alt="" />
                            
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/jeremy03.jpg" className="img-fluid" alt="" />
                            
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/jeremy04.jpg" className="img-fluid" alt="" />
                            
                        </div>
				    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
