import { min } from 'rxjs';
import { getData } from './dataServices'

export class globalConstants {
    //static API_URI = 'http://localhost:9520/';
    //static API_URI = 'http://192.168.1.8:9520/';
    static API_URI = 'https://api.bizmindtv.fr/';
    static IMAGES_URI = 'https://resources.galaxiessoftware.com/REDETRABAJO/images/';


    //product prices for dev
    // static products = {
    //     business: 'price_1PRkBlRqQvM9h8OAPFyUx3w9',
    //     mindset: 'price_1PRkAzRqQvM9h8OAE3vJNlaD',
    //     full: 'price_1PRkCKRqQvM9h8OAs0eeTqs8'
    // }

    // product prices for prod
    static products = {
        business: 'price_1PRkQhRqQvM9h8OA6qWwWKym',
        mindset: 'price_1PRkJBRqQvM9h8OAUTZx1pNh',
        full: 'price_1PRkRZRqQvM9h8OAaim3qtQj'
    }

    static socialLinks = {
        "instagram": "https://www.instagram.com/bmtvoff",
        "youtube": "https://youtube.com/@bmtvoff?si=RzAw1I2TmIuF6pK7",
        "facebook": "https://www.facebook.com/share/uFGVHchMErg8SkSG/?mibextid=LQQJ4d",
        "tiktok": "https://www.tiktok.com/@bmtvoff",
        "twitter": "https://x.com/bmtvoff",
        "linkedin": "https://www.linkedin.com/in/jeremy-watier-58532629b",
        "email": "bizmindtv@gmail.com"
    }
}


export async function getVariables(var_name) {
    const body = {
        "call": "get_variable",
        "var_str": var_name,
    }
    try {
        const res = await getData(body);
        if (res !== '') {
            return res;
        }
    } catch (error) {
        console.log('Error: ', error);
        throw error;
    }
}