import React from 'react'

export function YoutubeSocialComponent() {
    return (
        <div className="mt-5">
            <div className="container">

                <p>
                    Pilier principal de BizMind TV, La chaine youtube est de loin notre support majeur de communication visant à exprimer au grand public de nombreuses anecdotes, enseignements, concepts théoriques et pratiques ainsi que de précieux conseils aussi riches que variés.
                </p>
                <p>
                    Sur la chaîne officielle de #BMTV, vous profiterez de deux vidéos chaque semaine (le jeudi à 17h00 et le dimanche à 10h00) qui couvriront d’innombrables sujets de la vie de tous les jours et ce dans les « domaines » : professionnel, financier, développement personnel, mais aussi des vlogs, des voyages ..
                </p>
                <p>
                    👉 Nous vous recommandons de vous abonner dès maintenant et d’activer les notifications grâce au symbole de la petite cloche afin de recevoir toutes les informations ainsi que toutes les dernières vidéos en temps réel.
                </p>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="text-center">
                            <h2 className="font-weight-bold text-6 text-danger">🎬 LA CHAÎNE YOUTUBE #BMTV</h2>
                        </div>
                        <img className="img-fluid" height={120} src="./assets/img/social/youtube.jpg" alt="jeremy watier YouTube" />
                    </div>
                </div>
            </div>
        </div>
    )
}
