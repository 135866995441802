import React, { useState } from 'react'
import AccountAside from './AccountAside'
import { HeaderComponent } from '../../components/HeaderComponent'
import { FooterComponent } from '../../components/FooterComponent'
import { useUserData } from '../../shared/UserDataContext'
import Swal from 'sweetalert2'
import { changePass } from '../../shared/dataServices'


export default function ChangePassPage() {

    const token = useUserData().readToken();
    const [passData, setPassData] = useState(
        {
            id_user: token.nameid,
            current_pass: '',
            new_pass: '',
            cnew_pass: ''
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassData({ ...passData, [name]: value });
    }

    const saveData = () => {
        if (passData.current_pass === '' || passData.new_pass === '' || passData.cnew_pass === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Veuillez remplir tous les champs',
            })
            return;
        }

        if (passData.new_pass !== passData.cnew_pass) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Les mots de passe ne correspondent pas',
            })
            return;
        }

        changePass(passData).then((data) => {
            Swal.fire({
                icon: 'success',
                title: 'Succès',
                text: 'Votre mot de passe a été mis à jour avec succès',
            })
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur s\'est produite lors de la mise à jour de votre mot de passe \n' + error,
            })
        })



    }



    return (
        <div>
            <HeaderComponent />
            <div role="main" className="main bg-color-grey-scale-1">
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-lg-3 mt-4 me-2 py-4 mt-lg-0 bg-light rounded">
                            <AccountAside></AccountAside>
                        </div>
                        <div className="col-lg-8 bg-light p-4 rounded ml-3">
                            <div className="overflow-hidden mb-1">
                                <h2 className="font-weight-normal text-7 mb-0"><strong className="font-weight-extra-bold">Changer le </strong>mot de passe</h2>
                            </div>
                            <div className="overflow-hidden mb-4 pb-3">
                                <p className="mb-0">Pensez à utiliser un mot de passe fort composé de chiffres, de lettres et de caractères spéciaux</p>
                            </div>
                            <div className="row">

                                <div className="col-md-8 offset-1">
                                    <div className="form-group row">
                                        <label className="form-control-label col-lg-6">Entrer votre mot de passe actuel</label>
                                        <div className="col-lg-6">
                                            <input
                                                required
                                                minLength="8"
                                                type="password"
                                                placeholder="••••••••••"
                                                className="form-control"
                                                name='current_pass'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="form-control-label col-lg-6">Entrez votre nouveau mot de passe</label>
                                        <div className="col-lg-6">
                                            <input
                                                required
                                                minLength="8"
                                                type="password"
                                                placeholder="Minimum 8 caractères"
                                                className="form-control"
                                                name='new_pass'
                                                onChange={handleChange}
                                            />

                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="form-control-label col-lg-6">Répétez votre nouveau mot de passe</label>
                                        <div className="col-lg-6">
                                            <input
                                                required
                                                minLength="8"
                                                type="password"
                                                id="confNewPass"
                                                placeholder="Minimum 8 caractères"
                                                className="form-control"
                                                name='cnew_pass'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-6 offset-6 text-right">
                                            <button onClick={saveData} className="btn btn-info"><i className="fa-solid fa-key me-2"></i>Envoyer</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent />
            </div>
        </div>
    )
}