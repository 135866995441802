import React, { useEffect, useState } from 'react'
import { HeaderComponent } from '../../components/HeaderComponent'
import PlayerComponent from '../../components/Player/PlayerComponent'
import { getData, getList, getVideosList } from '../../shared/dataServices'
import Swal from 'sweetalert2'
import { useUserData } from '../../shared/UserDataContext'


export default function PlayerPage() {
    const [videoList, setVideoList] = useState([])
    const { loggedIn, readToken } = useUserData();
    const token = readToken();


    useEffect(() => {
        console.log(token);
        async function fetchData() {
            const plan = await getUserPlan(token.nameid)
            await fetchVideos(plan)
        }

        fetchData()
    }, [])

    const getUserPlan = async (uid) => {
        let body = {
            llamado: 'user_data',
            var_texto: uid
        }

        const res = await getData(body).then((data) => {
            return data.plan
        }).catch((error) => {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur s\'est produite lors de la récupération des données utilisateur' + error,
            })
        })

        return res;

    }


    const fetchVideos = async (plan) => {

        await getVideosList(plan).then((data) => {
            setVideoList(data.items)
        }).catch((error) => {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur s\'est produite lors de la récupération des vidéos' + error,
            })
        })

    }

    return (
        <div>
            <HeaderComponent />
            <div role='main' className='main bg-color-grey-scale-1'>
                <div className="container">
                    <div className="row">
                        {
                            loggedIn ?
                                <div className='row'>
                                    <div className="col-md-12 py-4">
                                        <PlayerComponent videoItem={videoList[0]} />
                                    </div>
                                    <div className="col-md-12 py-3">
                                        <div className="row">
                                            {
                                                videoList.map((video, index) => {
                                                    return (
                                                        <div key={index} className="col-md-3 mt-4">
                                                            <a href={"/video-player?guid=" + video.guid}>
                                                                <img src={'https://vz-7001f9c3-318.b-cdn.net/' + video.guid + '/' + video.thumbnailFileName}
                                                                    alt={video.title}
                                                                    className="img-fluid" />
                                                            </a>
                                                            <div className="col-md-12">
                                                                <a href={"/video-player?guid=" + video.guid}>
                                                                    <span className='fs-6 text-dark fw-bold'>{video.title}</span>
                                                                </a>
                                                                <div>
                                                                    <small>Jeremy Watier </small> <i className='fa fa-check-circle text-secondary'></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-md-12 py-3">
                                    <div className="alert alert-warning" role="alert">
                                        Vous devez être connecté pour accéder à cette page
                                        <img className='img-fluid' src="./assets/img/banners/empty.jpg" alt="" />
                                    </div>
                                </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
