import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { FooterComponent } from '../components/FooterComponent'

export default function MentionsPage() {
    return (
        <div>
            <HeaderComponent />
            <div role='main' className="main">
                <div className="container">
                    <h1 className='text-warning fw-bold'>MENTIONS LÉGALES</h1>
                    <p>
                        En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site https://bizmindtv.fr/ l’identité des différents intervenants dans le cadre de sa conception et de son suivi :
                    </p>

                    <div class="et_pb_text_inner">
                        <p style={{ textAlign: 'center' }}>
                            <strong>1. Hébergeur :</strong> OVH SAS<br />
                            <strong>Adresse du siège social :</strong> 2 rue Kellermann – 59100 Roubaix – France<br />
                            <strong>Numéro de téléphone :</strong> 09 72 10 10 07</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>2. Informations relatives à l’éditeur</strong>
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>Nom – Prénom :</strong> Jeremy Watier<br />
                            <strong>Forme juridique :</strong> entrepreneur individuel<br />
                            <strong>Enseigne :</strong><br />
                            <strong>Adresse :</strong> 30 rue de Broceliande, Guidel 56520<br />
                            <strong>Téléphone :</strong> +33 6 19 57 97 45<br />
                            <strong>E-mail :</strong> bizmindtv@gmail.com<br />
                            <strong>Directeur de publication :</strong> Jeremy Watier & Santiago Mora<br />
                            <strong>Siren : 91068895100011</strong><br />
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>3. Conditions générales d’utilisation</strong>
                        </p>
                        <p>En accédant à ce site vous consentez aux conditions définies ci-après.</p>
                        <p>D’un droit d’usage privé, personnel et non transmissible sur le contenu du site ou de l’un de ses éléments.</p>
                        <p>Les sites opérant des liens vers https://bizmindtv.fr/ exercent une activité et diffusent des informations, images et tout autre support en conformité avec l’ordre public et les bonnes mesures et d’une manière générale sont conformes au droit français.</p>
                        <p>Jeremy Watier se réserve le droit de modifier ou de corriger le contenu de ce site à tout moment et sans préavis. Les liens hypertextes établis en direction d’autres sites à partir de https://bizmindtv.fr/ ne sauraient, en aucun cas, engager la responsabilité de Jeremy Watier.</p>
                        <p style={{ textAlign: 'center' }}><strong>4. Description des services fournis</strong></p>
                        <p>Le site https://bizmindtv.fr/ a pour objet de fournir une information concernant l’ensemble des activités.</p>
                        <p>Jeremy Watier s’efforce de fournir sur le site https://bizmindtv.fr/ des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes ou des carences dans la mise à jour.</p>
                        <p>Toutes les informations indiquées sur https://bizmindtv.fr/ sont données à titre indicatif, et sont susceptibles d’évoluer. Par conséquent, les renseignements figurant sur le site https://bizmindtv.fr/ ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>

                        <p style={{ textAlign: 'center' }}>
                            <strong>5. Limitations contractuelles sur les données techniques</strong>
                        </p>

                        <p>Le site utilise la technologie React.</p>
                        <p>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>6. Accessibilité</strong>
                        </p>
                        <p>Le site https://bizmindtv.fr/ est par principe accessible aux utilisateurs 24/24h, 7/7j, sauf interruption, programmée ou non, pour les besoins de sa maintenance ou en cas de force majeure. En cas d’impossibilité d’accès au service, Jeremy Watier s’engage à faire son maximum afin de rétablir l’accès au service. N’étant soumis qu’à une obligation de moyen, Jeremy Watier  ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du service.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>7. Limitation de responsabilité</strong>
                        </p>
                        <p>Jeremy Watier ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site  https://bizmindtv.fr/</p>
                        <p>Jeremy Watier ne pourra également être tenu responsable des dommages indirects (comme par exemple une perte de marché) consécutifs à l’utilisation du site  https://bizmindtv.fr/.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>8. Protection des données personnelles</strong>
                        </p>
                        <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
                        <p>A l’occasion de l’utilisation du site  https://bizmindtv.fr/, peuvent êtres recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site  https://bizmindtv.fr/, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.</p>
                        <p>En tout état de cause, Jeremy Watier ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par  https://bizmindtv.fr/ . L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur de  https://bizmindtv.fr/  ll’obligation ou non de fournir ces informations.</p>
                        <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>
                        <p>Le site n’est pas déclaré à la CNIL car aucune donnée personnelle n’est recueillie sur ce site.</p>
                        <p>
                            <strong>a. Collecte et protection des données</strong>
                        </p>
                        <p>Vos données sont collectées par Jeremy Watier.<br />
                            Une donnée à caractère personnel désigne toute information concernant une personne physique identifiée ou identifiable (personne concernée); est réputée identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un nom, un numéro d’identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.<br />Les informations personnelles pouvant être recueillies sur le site sont principalement utilisées par l’éditeur pour la gestion des relations avec vous.<br />Les données personnelles collectées dans les formulaires de contact sont les suivantes :</p>
                        <p>Nom et prénom<br />Adresse mail<br />Numéro de téléphone<br />Afin de traiter votre demande, Jeremy Watier devra effectuer un traitement des données personnelles vous concernant.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>9. Propriété intellectuelle</strong>
                        </p>
                        <p>Jeremy Watier est propriétaire exclusif de tous les droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, tant sur la structure que sur les textes, images, graphismes, logo, icônes…</p>
                        <p>Toute reproduction totale ou partielle du site https://bizmindtv.fr/, représentation, modification, publication, adaptation totale ou partielle de l’un de ces éléments est interdite, sauf autorisation écrite préalable de Jeremy Watier. À défaut, elle sera considérée comme constitutive d’une contrefaçon et passible de poursuite conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>10. Gestion des cookies</strong>
                        </p>
                        <p>Un cookie est un petit fichier texte enregistré sur l’ordinateur d’un internaute lorsqu’il visite une page web. Les cookies utilisés sur https://bizmindtv.fr/ ne permettent pas de vous identifier mais facilitent votre navigation. Ces cookies permettent également d’analyser la fréquentation du site pour renforcer votre sécurité et améliorer https://bizmindtv.fr/</p>
                        <p>Le refus d’installation des cookies peut compromettre l’accès à certaines parties du site. Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de la refuser de la manière décrite à l’adresse suivante : www.cnil.fr.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>11. Litiges</strong>
                        </p>
                        <p>Jeremy Watier met tout en œuvre pour donner entière satisfaction à ses Clients, les renseigner et traiter toute réclamation éventuelle. Jeremy Watier informe le Client qu’il a le droit de recourir à un médiateur de la consommation en vue de la résolution amiable du litige qui l’oppose à un professionnel lorsque ce litige n’a pas pu être réglé dans le cadre d’une réclamation préalable directement introduite auprès de ses services.</p>
                        <p>Les présentes conditions sont régies par les lois françaises et toute contestation ou litige qui pourrait naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société Jeremy Watier Médecine chinoise. La langue de référence, pour le règlement de contentieux éventuels, est le français.</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>12. Prestataire technique</strong>
                        </p>
                        <p>Les prestations techniques sont assurées par Web d’Impact, micro-entreprise dont le siège social est situé au 15 avenue de la Gloire, 31500, Toulouse.</p>
                        <p style={{ textAlign: 'center' }}><span style={{ color: '#382e2e' }}><a href="https://webdimpact.com/" style={{ color: '#382e2e' }}><strong>https://webdimpact.com/</strong></a></span></p></div>
                </div>

                <FooterComponent />
            </div>

        </div>
    )
}
