import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { FooterComponent } from '../components/FooterComponent'
import { BioHeaderComponent } from '../components/Bio/BioHeaderComponent'
import { FullBioCompoonent } from '../components/Bio/FullBioCompoonent'


export function BioPage() {
  return (
    <div>
    <HeaderComponent />
      <div role="main" class="main">
        <BioHeaderComponent />
        <FullBioCompoonent />
        <FooterComponent />
      </div>
    </div>
  )
}
