import React from 'react'

export function FullBioCompoonent() {
  return (
    <div>
        <div className="container">
            <div className=" text-center mt-4">
                <h1 className="font-weight-bold text-8 text-danger">
                    📕 BIOGRAPHIE - DES TÉNÈBRES À LA LUMIÈRE !
                </h1>
                <h3 className="font-font-weight-medium"> Jeremy Waiter </h3>
                <div className="row">
                    <div className="col-md-8 text-justify">
                        <p>
                            Jeremy Watier né le 9 octobre 2001 est un entrepreneur passionné, un chercheur de vérité et un guide spirituel dévoué. 

                        </p>
                        <p>
                            Dès son plus jeune âge, Jeremy s'est immergé dans le monde du business en ligne et du mindset, cherchant à comprendre les rouages de la réussite et de l'épanouissement personnel. 
                        </p>
                        <p>
                            Sa quête l'a conduit à explorer une variété de sources, des vidéos inspirantes sur YouTube aux enseignements ésotériques des sociétés secrètes, en passant par les paroles percutantes des chansons de rap.
                        </p>
                        <p>
                            À l'âge de 16 ans, Jeremy a fait l'achat de sa première formation e-commerce, lançant ainsi son voyage dans l'entrepreneuriat. 
                        </p>
                        <p>
                            Depuis lors, il n'a jamais cessé de chercher à approfondir ses connaissances et à partager ses découvertes avec le monde. Sa détermination à enseigner et à inspirer les autres à vivre une vie épanouissante l'a amené à créer sa propre société, où il partage ses idées, ses conseils et son expertise avec une communauté croissante de personnes désireuses de réussir dans le monde des affaires et du développement personnel.
                        </p>
                        <p>
                            Parallèlement à ses activités entrepreneuriales, Jeremy a toujours été un chercheur spirituel avide, explorant diverses traditions philosophiques et ésotériques pour enrichir sa compréhension du monde et de lui-même. Son initiation auprès de certaines sociétés secrètes a été une étape clé dans son parcours, lui offrant des perspectives uniques sur la nature de la réalité et sur son propre chemin spirituel.
                        </p>
                        <p>
                            Aujourd'hui, Jeremy s'engage à partager sa sagesse, son expérience et son inspiration avec ceux qui cherchent à créer une vie et une entreprise alignées avec leurs aspirations les plus profondes. À travers ses écrits, ses conférences et ses programmes de formation, il guide les autres sur le chemin de la réussite et de l'épanouissement, tout en continuant sa propre quête de croissance personnelle et de développement spirituel.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="owl-carousel owl-theme full-width dots-inside float-start" data-plugin-options="{'items': 1, 'loop': true, 'nav': false, 'dots': true, 'autoplay': true, 'autoplayTimeout': 3000}">
                        <div>
                            <img src="/assets/img/jeremy/full_bio3.jpg" className="img-fluid" alt="" />
                            
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/full_bio2.jpg" className="img-fluid" alt="" />
                            
                        </div>
                        <div>
                            <img src="/assets/img/jeremy/full_bio1.jpg" className="img-fluid" alt="" />
                            
                        </div>
				    </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-7">
                        <div className="float-end appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="200">
                            <blockquote className="blockquote-primary blockquote-reverse">
                                <p>
                                        “ “ On ne change jamais les choses <strong>en combattant </strong> la réalité existante. Pour changer quelque chose,  <strong>construisez un nouveau modèle qui rende l’ancien obsolète</strong> ”
                                </p>
                                <footer className="blockquote-footer">R. BUCKMINSTER FULLER</footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="col-md-5 pb-4">
                        
                    </div>
                </div>
                
            </div>

        </div>
    </div>
  )
}
