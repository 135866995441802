import React from 'react'
import { PromobarComponent } from './PromobarComponent'
import { useUserData } from '../shared/UserDataContext'
import { useCart } from '../shared/CartDataContext';

export function HeaderComponent() {

    const { loggedIn, readToken, logOut } = useUserData();
    const token = readToken();

    const { cart, removeItem, getItemsCount, getTotalValue } = useCart();




    return (
        <div id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 164, 'stickySetTop': '-164px', 'stickyChangeLogo': false}">
            <div className="border-0">
                <PromobarComponent />
                <div className="header-container header-container-md container">
                    <div className="header-row">
                        <div className="header-column">
                            <div className="header-row">
                                <div className="header-logo">
                                    <a href="/">
                                        <img alt="bizmind" width="220" data-sticky-width="82" data-sticky-height="40" data-sticky-top="0" src="./assets/img/logos/bizmind_logo.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="header-column justify-content-end">
                            <div className="header-row">
                                <div className="header-nav header-nav-line header-nav-bottom-line header-nav-bottom-line-no-transform header-nav-bottom-line-active-text-dark header-nav-bottom-line-effect-1 order-2 order-lg-1">
                                    <div className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                                        <nav className="collapse">
                                            <ul className="nav nav-pills" id="mainNav">
                                                <li className="dropdown">
                                                    <a className="dropdown-item dropdown-toggle" href="/">
                                                        <i className="fa fa-home"></i>
                                                    </a>
                                                </li>
                                                <li className="dropdown">
                                                    <a className="dropdown-item dropdown-toggle" href="/a-propos-de-bsmtv">
                                                        À PROPOS DE #BMTV
                                                    </a>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/jeremy-watier">Jeremy Watier</a></li>
                                                        <li><a className="dropdown-item" href="/jeremy-watier-biographie">Biographie Complète</a></li>
                                                        <li><a className="dropdown-item" href="/reseaux-sociaux-presse">Social & Presse</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <a className="dropdown-item dropdown-toggle" href="/rejoindre-le-club-prive-de-lsda">
                                                        LE CLUB PRIVÉ
                                                    </a>
                                                </li>
                                                <li className="dropdown">
                                                    <a className="dropdown-item dropdown-toggle" href="/mes-programmes-videos">
                                                        MES PROGRAMMES VIDÉOS
                                                    </a>
                                                </li>
                                                <li className="dropdown">
                                                    <a className="dropdown-item dropdown-toggle" href="/contacter-bsmtv">
                                                        CONTACT
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <button className="btn header-btn-collapse-nav" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
                                        <i className="fas fa-bars"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-row">
                        <div className="header-nav-features header-nav-features-no-border header-nav-features-lg-show-border order-1 order-lg-2">
                            <div className="header-nav-feature header-nav-features-cart d-inline-flex">
                                <h6 className="text-dark me-2">
                                    <span className="font-weight-bold">
                                        {
                                            loggedIn ? 'Accueillir' : 'Commencer la'
                                        }
                                    </span><br />
                                    {
                                        (loggedIn && token) ? token.unique_name : 'session'
                                    }
                                </h6>
                                <a href="/" data-bs-toggle={!loggedIn && 'modal'} data-bs-target="#loginModal" onClick={() => {
                                    if (!loggedIn) {
                                        const headerTopUserDropdown = document.getElementById('headerTopUserDropdown');
                                        headerTopUserDropdown.classList.toggle('show');
                                    }
                                }} className="header-nav-features-toggle">
                                    <img src={loggedIn ? 'assets/img/icons/icon-video-big.svg' : 'assets/img/icons/icon-user2-big.svg'} width="32" alt="" className="header-nav-top-icon-img mt-1" />
                                </a>
                                <div className="header-nav-features-dropdown mt-5 p-0 pb-3 rounded" id="headerTopUserDropdown">
                                    <div>
                                        <div className="rounded-top p-3" style={{ backgroundImage: 'url(./assets/img/banners/bright_squares.png)' }}>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <img src="./assets/img/icons/account.png" width="60" alt="" />
                                                </div>
                                                <div className="col-lg-9">
                                                    <span className="font-weight-bold">{token ? token.unique_name : ''}</span><br />
                                                    <small className="font-weight-semibold mt-0">{token ? token.email : ''}</small>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">

                                        <div className="col-lg-6 p-4 border-right text-center">
                                            <a href="/my-account" className="text-color-tertiary">
                                                <img src="./assets/img/icons/icon-userTop-big.svg" width="40" alt="" /><br />
                                                <span className="font-weight-bold">Mon compte</span>
                                            </a>
                                        </div>

                                        <div className="col-lg-6 p-4 text-center">
                                            <a href="/change-password" className="text-color-tertiary">
                                                <img src="./assets/img/icons/icon-password-big.svg" width="40" alt="" /><br />
                                                <span className="font-weight-bold">Changer le mot de passe</span>
                                            </a>
                                        </div>
                                        <div className="col-lg-12 border-top p-4 text-center">
                                            <a href="/video-player" className="text-color-tertiary">
                                                <img src="./assets/img/icons/icon-player-big.svg" width="40" alt="" /><br />
                                                <span className="font-weight-bold">Accéder au contenu</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-info rounded-0 btn-block" onClick={() => {
                                            logOut()
                                            window.location.href = '/';
                                        }
                                        }>
                                            <i className="fa-solid fa-right-from-bracket me-2"></i>
                                            Fermer la session
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-nav-features header-nav-features-no-border header-nav-features-lg-show-border order-1 order-lg-2">
                            <div className="header-nav-feature header-nav-features-cart d-inline-flex">
                                <a href="/" className="header-nav-features-toggle">
                                    <img src="assets/img/icons/icon-cart-big.svg" width="24" alt="" className="header-nav-top-icon-img" />
                                    <span className="cart-info">
                                        <span className="cart-qty">{getItemsCount()}</span>
                                    </span>
                                </a>
                                <div className="header-nav-features-dropdown" id="headerTopCartDropdown">
                                    <ol className="mini-products-list">

                                        {
                                            cart.cartItems.length === 0 ?
                                                <div className="text-center">
                                                    <div>
                                                        <img src="./assets/img/icons/empty.png" alt='empty' className='img-fluid' />
                                                    </div>
                                                    <span className="h2">Votre panier est toujours vide</span>
                                                </div>
                                                :
                                                cart.cartItems.map((item, index) => (
                                                    <li className="item" key={index}>
                                                        <a href="/" title="Product" className="product-image">
                                                            <img src="assets/img/logos/icon.png" alt="" />
                                                        </a>
                                                        <div className="product-details">
                                                            <p className="product-name">
                                                                <span className='fw-bold'>{item.productName}</span>
                                                            </p>
                                                            <p className="qty-price">
                                                                <span className="price">{item.value}€</span>
                                                            </p>
                                                            <a href='/' onClick={(event) => {
                                                                event.preventDefault();
                                                                removeItem(index)
                                                            }} title="Remove This Item" className="btn-remove"><i className="fas fa-times"></i></a>
                                                        </div>

                                                    </li>
                                                ))
                                        }

                                    </ol>
                                    {
                                        cart.cartItems.length > 0 &&
                                        <div>
                                            <div className="totals">
                                                <span className="label">Total:</span>
                                                <span className="price-total"><span className="price">{getTotalValue()}€</span></span>
                                            </div>
                                            <div className="actions">
                                                <a className="btn w-100 btn-primary" href="/checkout">Checkout</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
