import React from 'react'

export function InstagramSocialComponent() {
    return (
        <div className="mt-4 text-center">
            <div className="text-center">
                <h2 className="font-weight-bold text-6 text-danger">LE COMPTE INSTAGRAM #BMTV</h2>
            </div>
            <div>
                <p>
                    Pour ceux qui veulent me suivre. J’ai également créé un compte Instagram.
                </p>
                <p>
                    Je vous partage des réels et des photos de ma vie de tous les jours sous forme de « Stories » temporaires accessibles seulement pendant 24 heures.
                </p>
                <p>
                    Vous retrouverez ainsi un certain nombre de « flashs » souvent très inspirants de mon quotidien: business, voyages, loisirs, automobiles, motivation, mindset avec toujours une bonne dose de fun, d’humour et d’amour !
                </p>
                <img src="./assets/img/social/instagram.jpg" className="img-fluid" alt="" />
            </div>
        </div>
    )
}
