import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { SliderComponent } from '../components/Index/SliderComponent/SliderComponent'
import { BioComponent } from '../components/Index/BioComponent/BioComponent'
import { GlobeTrotterComponent } from '../components/Index/GlobeTrotterComponent/GlobeTrotterComponent'
import { FooterComponent } from '../components/FooterComponent'

export function HomePage() {
  return (
    <div>
      <HeaderComponent />
      <div role="main" className="main">
        <SliderComponent />
        <BioComponent />
        <GlobeTrotterComponent />
        <FooterComponent />
      </div>
    </div>
  )
}
