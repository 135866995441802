import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { FooterComponent } from '../components/FooterComponent'
import { BsmtvHeaderComponent } from '../components/Bsmtv/BsmtvHeaderComponent'
import { BsmtvTextComponent } from '../components/Bsmtv/BsmtvTextComponent/BsmtvTextComponent'


export function BsmtvPage() {
  return (
    <div>
    <HeaderComponent />
      <div role="main" class="main">
        <BsmtvHeaderComponent />
        <BsmtvTextComponent />
        <FooterComponent />
      </div>
    </div>
  )
}
