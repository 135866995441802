import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { FooterComponent } from '../components/FooterComponent'
import { PrivateClubHeaderComponent } from '../components/PrivateClub/PrivateClubHeaderComponent'
import { PrivateClubComponent } from '../components/PrivateClub/PrivateClubComponent'

export function PrivateClubPage() {
  return (
    <div>
    <HeaderComponent />
      <div role="main" class="main">
        <PrivateClubHeaderComponent />
        <PrivateClubComponent />
        <FooterComponent />
      </div>
    </div>
  )
}
