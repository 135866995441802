import React from 'react'

export default function VideoMindsetComponent() {
    return (
        <div>

            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                <iframe title='Video Mindset' src="https://iframe.mediadelivery.net/embed/256628/8db80d81-ed1e-4e2e-bf59-9673953209ed?autoplay=false&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style={{ border: 0, position: 'absolute', top: 0, height: '100%', width: '100%' }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
            </div>
            <ul className='list list-icons list-primary'>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="0">
                    <i className="fas fa-check"></i> 1. Définition mindset :
                    <p>
                        Le mindset, ou état d'esprit, est l'ensemble des croyances et des attitudes qui influencent notre manière de penser, d'agir et de réagir aux situations. Dans cette formation, nous explorerons en profondeur ce concept et comment il peut être transformé pour favoriser la réussite entrepreneuriale.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <i className="fas fa-check"></i> 2. Pourquoi c’est important dans l’entrepreneuriat :
                    <p>
                        Votre mindset est crucial pour réussir en tant qu'entrepreneur. Il détermine votre capacité à surmonter les obstacles, à persévérer face aux échecs et à saisir les opportunités. Un état d'esprit positif et résilient vous permettra de rester motivé et de prendre des décisions stratégiques en toute confiance.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="600">
                    <i className="fas fa-check"></i> 3. Ce qui t’empêche d’atteindre la richesse :
                    <p>
                        Identifiez les croyances limitantes et les comportements autodestructeurs qui freinent votre réussite. Apprenez à reconnaître et à éliminer ces barrières mentales pour libérer tout votre potentiel.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="900">
                    <i className="fas fa-check"></i> 4. Pourquoi tu n’atteins pas la richesse :
                    <p>
                        Comprenez les raisons sous-jacentes qui vous empêchent d’atteindre vos objectifs financiers. Cette section vous aidera à analyser vos habitudes, vos peurs et vos perceptions de l'argent pour changer votre approche et vos actions.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="1200">
                    <i className="fas fa-check"></i> 5. Le secret d’Elon Musk :
                    <p>
                        Découvrez les principes et les stratégies de mindset qui ont contribué à la réussite spectaculaire d'Elon Musk. Inspirez-vous de son parcours pour adopter des techniques similaires dans votre propre entreprise.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="1500">
                    <i className="fas fa-check"></i> 6. 3 astuces pour exploser les compteurs
                    <p>
                        Apprenez trois techniques puissantes pour booster votre performance et atteindre des résultats exceptionnels. Ces astuces pratiques vous aideront à maximiser votre productivité, à rester focalisé sur vos objectifs et à surmonter les obstacles avec détermination.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="1800">
                    <i className="fas fa-check"></i> 7. Le comment vs l’attitude :
                    <p>
                        Explorez la différence entre savoir comment faire les choses et avoir l'attitude appropriée pour les accomplir. Comprenez pourquoi l'attitude peut souvent jouer un rôle plus crucial que les compétences techniques dans la réussite entrepreneuriale.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="2100">
                    <i className="fas fa-check"></i> 8. Cherche l’or :
                    <p>
                        Adoptez une mentalité de recherche d'opportunités dans chaque situation. Apprenez à voir les défis comme des opportunités cachées et développez une attitude proactive pour les exploiter. Cette approche vous aidera à toujours chercher et trouver des "pépites d'or" dans votre parcours entrepreneurial.
                    </p>
                </li>
                <li className="appear-animation animated fadeInUp appear-animation-visible" data-appear-animation="fadeInUp" data-appear-animation-delay="2400">
                    <i className="fas fa-check"></i> 9. Le lien entre religion et pauvreté expliqué :
                    <p>
                        Analysez les influences culturelles et religieuses qui peuvent impacter votre perception de la richesse et du succès. Comprenez comment certaines croyances peuvent limiter votre potentiel financier et découvrez comment réorienter votre mindset pour aligner vos objectifs de réussite avec vos valeurs personnelles. En vous inscrivant à notre formation, vous apprendrez non seulement à transformer votre état d'esprit pour maximiser votre réussite en affaires, mais aussi à identifier et surmonter les obstacles mentaux qui vous retiennent. Ne manquez pas cette opportunité de développer un mindset puissant et de faire un pas décisif vers la réalisation de vos ambitions entrepreneuriales.
                    </p>
                </li>
            </ul>
        </div>
    )
}
