import { globalConstants } from './globalConstants'


export const getList = async (body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/llamarListas';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        });
        return res;
    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const getData = async (body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const updateData = async (body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/';
    try {
        res = await fetch(uri, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const loginUser = async (_body) => {

    let res = '';
    let uri = globalConstants.API_URI + 'user/login';
    //let uri = 'http://localhost:9520/user/login';
    console.log(_body);
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else if (data.status === 321) {
                res = 'identifiant ou mot de passe incorrect';
                return Promise.reject(res);
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        throw error;
    }
}

export const registerUser = async (body) => {
    console.log('object');
    let res = '';
    let uri = globalConstants.API_URI + 'user/register';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const changePass = async (body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'user/change_pass';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else if (data.status === 321) {
                res = 'Ce n\'est pas votre mot de passe actuel';
                return Promise.reject(res);
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        throw error;
    }
}

export const getPayment = async (body) => {
    console.log(body);
    let res = '';
    let uri = globalConstants.API_URI + 'create-checkout-session';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}
export const getVideosList = async (plan) => {
    let res = '';
    let uri = globalConstants.API_URI + 'bunnyNet/getVideos/' + plan;
    try {
        res = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const getVideoData = async (guid) => {
    let res = '';
    let uri = globalConstants.API_URI + 'bunnyNet/getVideo/' + guid;
    try {
        res = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const sendContactForm = async (_body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/contact';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: _body
        }).then((data) => {
            if (data.status === 200) {
                console.log(data);
                return 'Correo enviado';
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const sendSubscribe = async (_body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/subscribe';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: _body
        }).then((data) => {
            if (data.status === 200) {
                console.log(data);
                return 'Correo enviado';
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const sendJoin = async (_body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/join';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: _body
        }).then((data) => {
            if (data.status === 200) {
                console.log(data);
                return 'Correo enviado';
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const getPaymentStatus = async (session_id) => {
    let res = '';
    let uri = globalConstants.API_URI + 'session-status?session_id=' + session_id;
    try {
        res = await fetch(uri, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const setUserPlan = async (body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'user/setPlan';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((data) => {
            if (data.status === 200) {
                return data.json();
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const send_mail_payment = async (email) => {
    let res = '';
    let uri = globalConstants.API_URI + 'globalservices/payment';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: email
        }).then((data) => {
            if (data.status === 200) {
                console.log(data);
                return 'Correo enviado';
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}

export const send_mail_password_reset = async (body) => {
    let res = '';
    let uri = globalConstants.API_URI + 'user/resetPassword';
    try {
        res = await fetch(uri, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: body
        }).then((data) => {
            if (data.status === 200) {
                console.log(data);
                return 'Correo enviado';
            } else {
                res = 'Error in module -- ' + data.status + ' -- ' + data.statusText;
                return Promise.reject(res);
            }
        })
        return res;

    } catch (error) {
        console.log('Error: ', error);
        return res;
    }
}


