import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

const JwtHelper = {
    isTokenExpired: (token) => {
        try {
            const { exp } = jwtDecode(token);
            return Date.now() >= exp * 1000;
        } catch {
            return true;
        }
    },
};

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const loggedInSubject = new BehaviorSubject(false);

    useEffect(() => {
        const isLoggedIn = checkLogin();
        setLoggedIn(isLoggedIn);
        loggedInSubject.next(isLoggedIn);
    }, []);

    const readToken = () => {
        const token = localStorage.getItem('AuthToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            //console.log(decodedToken);
            return decodedToken;
        }
        return null;
    };

    const logOut = () => {
        setLoggedIn(false);
        loggedInSubject.next(false);
        localStorage.removeItem('AuthToken');
    };

    const checkLogin = () => {
        const token = localStorage.getItem('AuthToken');
        if (!token || JwtHelper.isTokenExpired(token)) {
            setLoggedIn(false);
            localStorage.removeItem('AuthToken');
            return false;
        }
        setLoggedIn(true);
        return true;
    };

    return (
        <UserDataContext.Provider value={{ loggedIn, readToken, logOut, checkLogin, setLoggedIn }}>
            {children}
        </UserDataContext.Provider>
    );
};

export const useUserData = () => useContext(UserDataContext);
