import React from 'react'

const styles = {
    backgroundAbout: {
        backgroundImage: 'url(assets/img/banners/aboutBanner.jpg)',
        backgroundSize: 'cover',
    }
}

export function ContactHeaderComponent() {
  return (
     <section style={ styles.backgroundAbout } class="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-quaternary overlay-show overlay-op-8 mb-0" >
        <div class="container pt-5">
            <div class="row">
                <div class="col align-self-center p-static text-center mt-4">
 
                </div>
            </div>
        </div>
    </section>
  )
}
