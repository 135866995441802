import React from 'react'
import { globalConstants } from '../../../shared/globalConstants'

export function GlobeTrotterComponent() {
    return (
        <div className="mt-4">
            <div className="container">

                <div className="text-center">
                    <h2 className="font-weight-bold text-8 text-danger"><i className="fas fa-globe-africa"></i> #GLOBETROTTER</h2>
                    <p>Voyager à travers le monde m'a offert une expérience inestimable.</p>
                </div>
                <div className="row">
                    <div className="col-md-8 text-justify">
                        <div className="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="0">
                            <p>
                                Que ce soit en Afrique de l'Est, en Amérique centrale, sur de nombreuses îles, dans certaines villes du Moyen-Orient, ou dans divers pays d'Europe, chaque endroit a enrichi ma perspective sur le monde.
                            </p>
                            <p>
                                Cette diversité m'a poussé à m'intéresser à une multitude de sujets et a grandement ouvert mon esprit. C'est pourquoi aujourd'hui, je suis enthousiaste à l'idée de partager ces expériences avec vous.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="float-end appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="200">
                            <blockquote className="blockquote-primary blockquote-reverse">
                                <p>
                                    « Si vous pensez que l’aventure est dangereuse, essayez la routine, elle est mortelle. »
                                </p>
                                <footer className="blockquote-footer">Paulo Coelho</footer>
                            </blockquote>
                            <div className="mt-4 text-center">
                                <a target='_blank' rel='noreferrer' href={globalConstants.socialLinks.facebook}>
                                    <i className="fab fa-facebook text-primary fa-2x me-3"></i>
                                </a>
                                <a target='_blank' rel='noreferrer' href={globalConstants.socialLinks.youtube}>
                                    <i className="fab fa-youtube text-danger fa-2x me-3"></i>
                                </a>
                                <a target='_blank' rel='noreferrer' href={globalConstants.socialLinks.instagram}>
                                    <i className="fab fa-instagram text-danger fa-2x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <a href="/a-propos-de-bsmtv" className="btn btn-rounded btn-primary btn-xl btn-3d">EN SAVOIR PLUS À PROPOS DE #BMTV</a>
                        </div>
                        <div className="col-md-6">
                            <a href="/mes-programmes-videos" className="btn btn-rounded btn-primary btn-xl btn-3d">DÉCOUVRIR TOUS NOS PROGRAMMES VIDÉOS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
