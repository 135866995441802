//import React from 'react'
import { globalConstants } from '../shared/globalConstants'
import React, { useState } from 'react';
import { sendSubscribe } from '../shared/dataServices';
import Swal from 'sweetalert2'

export function FooterComponent() {
	const [formData, setFormData] = useState({
		email: ''
	});
	// const [success, setSuccess] = useState(false);
	// const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// setError(false);
		// setSuccess(false);
		let body = JSON.stringify(formData)




		const response = await sendSubscribe(body)
			.then((data) => {
				setLoading(false)
				// setError(false)
				// setSuccess(true)
				Swal.fire({
					title: 'Message envoyé',
					icon: 'success',
					text: 'Votre message a été envoyé avec succès, nous vous contacterons bientôt'
				})
				return data
			}).catch((error) => {
				Swal.fire({
					title: 'Votre message na pas été envoyé',
					icon: 'error',
					text: 'nous avons eu un problème ' + error
				})
				console.log(error)
			})

		console.log(response)

	};

	return (
		<footer id="footer" className="footer-texts-more-lighten">
			<div className="container">
				<div className="row py-4 my-5">
					<div className="col-md-3 col-lg-3 mb-5 mb-lg-0">
						<h5 className="text-4 text-color-light mb-3">CONTACT INFO</h5>
						<ul className="list list-unstyled">
							{/* <li className="pb-1 mb-2">
								<span className="d-block font-weight-normal line-height-1 text-color-light">ADRESSE</span>
								30 rue Brocéliande, Guidel
							</li> */}
							<li className="pb-1 mb-2">
								<span className="d-block font-weight-normal line-height-1 text-color-light">TEL</span>
								<a href="tel:+33619579745">+33619579745</a>
							</li>
							<li className="pb-1 mb-2">
								<span className="d-block font-weight-normal line-height-1 text-color-light">EMAIL</span>
								<a href={
									'mailto:' + globalConstants.socialLinks.email
								}>{globalConstants.socialLinks.email}</a>
							</li>
						</ul>
						<ul className="social-icons social-icons-clean-with-border social-icons-medium">
							<li className="social-icons-instagram">
								<a href={globalConstants.socialLinks.instagram} rel="noreferrer" className="no-footer-css" target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a>
							</li>
							<li className="social-icons-youtube mx-2">
								<a href={globalConstants.socialLinks.youtube} rel="noreferrer" className="no-footer-css" target="_blank" title="Twitter"><i className="fab fa-youtube"></i></a>
							</li>
							<li className="social-icons-facebook">
								<a href={globalConstants.socialLinks.facebook} rel="noreferrer" className="no-footer-css" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a>
							</li>
						</ul>
					</div>

					<div className="col-md-2  mb-5 mb-md-0 text-center">
						<h1 className="text-color-light font-weight-bold mb-3">
							<a href="/mes-programmes-videos">
								Regarder <br /> le <br /> programme
							</a>
						</h1>
					</div>
					<div className="col-md-3 text-center">
						<img src="./assets/img/jeremy/jeremy_watier_footer.png" style={{ height: '223px' }} className="img-fluid p-relative z-index-1 appear-animation" alt="" data-appear-animation="fadeIn" data-appear-animation-delay="200" />

						{/* <article className="mb-3">
								<a href="blog-post.html" className="text-color-light text-3-5">Why should I buy a Web Template?</a>
								<p className="line-height-2 mb-0"><a href="/">Nov 25, 2020</a> in <a href="/">Design,</a> <a href="/">Coding</a></p>
							</article>
							<article className="mb-3">
								<a href="blog-post.html" className="text-color-light text-3-5">Creating Amazing Website with Porto</a>
								<p className="line-height-2 mb-0"><a href="/">Nov 25, 2020</a> in <a href="/">Design,</a> <a href="/">Coding</a></p>
							</article>
							<article>
								<a href="blog-post.html" className="text-color-light text-3-5">Best Practices for Top UI Design</a>
								<p className="line-height-2 mb-0"><a href="/">Nov 25, 2020</a> in <a href="/">Design,</a> <a href="/">Coding</a></p>
							</article> */}
					</div>
					<div className="col-md-4">
						<h5 className="text-4 text-color-light mb-3">ABONNEZ-VOUS À LA NEWSLETTER</h5>
						<p className="mb-2">À travers mon programme Challenge BizMind , je vous accompagne sur le chemin de la réussite et de l'épanouissement.</p>
						<div className="alert alert-success d-none" id="newsletterSuccess">
							<strong>Success!</strong> You've been added to our email list.
						</div>
						<div className="alert alert-danger d-none" id="newsletterError"></div>

						<form id="newsletterForm" className="form-style-5 opacity-10" action="php/newsletter-subscribe.php" method="POST" onSubmit={handleSubmit}>
							<div className="row">
								<div className="input-group my-3">
									<input
										type="email"
										value={formData.email}
										onChange={handleChange}
										data-msg-required="Please enter your email."
										maxLength="100"
										className="form-control text-3 h-auto py-2"
										name="email"
										placeholder='Email'
										required
									/>

									<button type="submit" className="btn btn-primary btn-rounded btn-px-4 btn-py-2 font-weight-bold" disabled={loading}>
										{loading ? "Attendez" : "S'inscrire"}
									</button>
									{/* <input className="form-control" placeholder="Mail" name="newsletterEmail" id="newsletterEmail" type="text" />
									<button className="btn btn-primary btn-rounded btn-px-4 btn-py-2 font-weight-bold" type="submit">SUBSCRIBE</button> */}
								</div>
							</div>
							<div className="row">
								<div className="form-group col">
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="footer-copyright footer-copyright-style-2 pt-3 pb-3">
					<div className="row">
						<div className="col-12 text-center">
							<p className="mb-0">
								<a href="https://webdimpact.com/">
									© 2024 WEB D’IMPACT TOUS DROITS RÉSERVÉS RÉALISATION | WEB D’IMPACT
								</a>
							</p>
							<a href="/mentions-legales">
								MENTIONS LEGALES
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterComponent;
