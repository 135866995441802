import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getVideoData } from '../../shared/dataServices';

export default function PlayerComponent() {
    const [videoItem, setVideoItem] = useState(null)
    const [videoDescription, setVideoDescription] = useState('')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guid = queryParams.get('guid');

    const getVideo = async () => {
        await getVideoData(guid).then((data) => {
            setVideoItem(data)
            console.log(data);
            setVideoDescription(videoItem ? videoItem.metaTags[0].value : '')
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        async function fetchData() {
            if (guid) {
                await getVideo()
                console.log(videoItem);
            }
        }

        fetchData()
    }, [])

    return (
        <div>
            <div style={{ position: 'relative', paddingTop: '56.25%', marginBottom: 40 }}>
                {
                    videoItem ?
                        <iframe title='video' src={'https://iframe.mediadelivery.net/embed/' + videoItem.videoLibraryId + '/' + videoItem.guid + '?autoplay=true&loop=false&muted=false&preload=true&responsive=true'} loading="lazy" style={{ border: 0, position: 'absolute', top: 0, height: '100%', width: '100%' }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                        :
                        <iframe title='video' src="https://iframe.mediadelivery.net/embed/253441/1c78e82a-1c15-4bcc-b63a-50a5f3c13186?autoplay=true&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style={{ border: 0, position: 'absolute', top: 0, height: '100%', width: '100%' }} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                }
            </div>

            <div className="row">
                <div className="col-md-1">
                    <img src='./assets/img/jeremy/jeremy_watier.jpg' style={styles.userImage} alt='' />
                </div>
                <div className="col-md-8">
                    <h2 className='font-weight-bold p-0 my-0'>{videoItem ? videoItem.title : ''}</h2>
                    <p>Jeremy Watier</p>
                </div>
                <div className="col-md-3">
                    <button className='btn btn-rounded btn-dark float-end'> <i className='fa fa-bell me-1'></i> Abonné</button>
                </div>
            </div>
            <div className='bg-color-grey-scale-3 py-4 px-2 border-radius-2'>
                <p style={styles.videoDescription} >{videoItem ? videoItem.metaTags[0].value : ''}</p>
            </div>

        </div>
    )
}

const styles = {
    player: {
        width: '100%',
        height: 'auto',
        marginBottom: '20px',
        borderRadius: '5px'
    },
    thumbnail: {
        width: '100%',
        height: 'auto',
        marginBottom: '20px',
        borderRadius: '5px'
    },
    videoTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold'
    },
    videoDescription: {
        fontSize: '1rem'
    },
    userImage: {
        height: 'auto',
        borderRadius: '50%',
        maxWidth: '55px'
    }



}
