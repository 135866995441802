import React, { useEffect, useState } from 'react'
import { HeaderComponent } from '../../components/HeaderComponent'
import { FooterComponent } from '../../components/FooterComponent'
import AccountAside from './AccountAside'
import { useUserData } from '../../shared/UserDataContext'
import { updateData, getData } from '../../shared/dataServices'
import Swal from 'sweetalert2'

export default function AccountPage() {
    const token = useUserData().readToken();
    const [profileData, setProfileData] = useState(
        {
            user_: token.email,
            user_name_: token.unique_name,
            email: token.email,
            phone: '',
            address: '',
            gender: '',
        }
    )

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
    }

    const saveData = () => {
        console.log(profileData);
        profileData._id = token.nameid;
        let body = {
            StoreProcedure: 'SP_AdProfile',
            Valores: [profileData]
        }

        updateData(body).then((data) => {
            Swal.fire({
                icon: 'success',
                title: 'Succès',
                text: 'Vos données ont été mises à jour avec succès',
            })
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur s\'est produite lors de la mise à jour de vos données' + error,
            })
        })
    }
    useEffect(() => {
        getUserData();
    }, [])

    const getUserData = () => {
        let body = {
            llamado: 'user_data',
            var_texto: token.nameid
        }
        console.log(body);
        getData(body).then((data) => {
            console.log(data);
            setProfileData(data);
        }).catch((error) => {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur s\'est produite lors de la récupération de vos données' + error,
            })
        })
    }
    return (
        <div>
            <HeaderComponent />
            <div role="main" className="main bg-color-grey-scale-1">

                <div className="container pt-4">
                    <div className="row">
                        <div className="col-lg-3 mt-4 me-2 py-4 mt-lg-0 bg-light rounded">
                            <AccountAside></AccountAside>
                        </div>
                        <div className="col-lg-8 bg-light p-4 rounded ml-3">

                            <div className="overflow-hidden mb-1">
                                <h2 className="font-weight-normal text-7 mb-0"><strong className="font-weight-extra-bold">Mes </strong>données</h2>
                            </div>
                            <div className="overflow-hidden mb-4 pb-3">
                                <p className="mb-0">En complétant vos informations, vous pouvez effectuer des achats de manière plus rapide et plus sûre.</p>
                            </div>

                            <div role="form" className="needs-validation">
                                <div className="form-group row">
                                    <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">Des noms</label>
                                    <div className="col-lg-6">
                                        <input className="form-control" name='user_name_' required type="text" placeholder="John Doe" onChange={handleChanges} value={profileData.user_name_} />
                                    </div>
                                    <div className="col-lg-3">
                                        <select className="form-control" type="text" value={profileData.gender} name='gender' onChange={handleChanges} placeholder="Ciudad">
                                            <option value=""> -- Genre -- </option>
                                            <option>Mâle</option>
                                            <option>Femelle</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">Email</label>
                                    <div className="col-lg-9">
                                        <input className="form-control" name='email' required type="email" value={profileData.email} onChange={handleChanges} placeholder="email@gmail.com" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">Téléphone</label>
                                    <div className="col-lg-9">
                                        <input className="form-control" required type="email" placeholder="+XX XX XXX XXX" name='phone' value={profileData.phone} onChange={handleChanges} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2">Adresse</label>
                                    <div className="col-lg-9">
                                        <input className="form-control" type="text" placeholder="XXX X XX XX" name='address' value={profileData.address} onChange={handleChanges} />
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <div className="form-group col-lg-12 text-right mt-3">
                                        <button className="btn btn-info float-end" onClick={saveData} >Sauvegarder</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <FooterComponent />
            </div>
        </div>
    )
}
