import React from 'react'
import { ContactFormComponent } from './ContactFormComponent'
import { globalConstants } from '../../shared/globalConstants'


export function ContactComponent() {
  return (
    <div>
      <div>
        <div className="bg-color-light">
          <div className="container">
            <div className=" text-center pt-5">
              <h1 className="font-weight-bold text-8 text-danger">
                🗣 UNE QUESTION ? BESOIN D’AIDE ?
              </h1>
              <div className="row">
                <div className="col-md-8 text-justify">
                  <p>
                    🤝 Pour toute demande merci d’utiliser notre formulaire intelligent pour entrer en contact avec l’équipe de BMTV©.
                  </p>
                  <p>
                    Les demandes commerciales et administratives, factures et devis, demandes d’interviews et de partenariats sont également à adresser depuis cet espace.
                  </p>
                  <p>
                    Vous recevrez une réponse de notre équipe généralement sous 72 heures (sauf en cas d’absence temporaire vers une autre galaxie…).
                  </p>
                  <p>
                    Merci pour votre intérêt et comme dirait un certain Maître Yoda :
                  </p>
                  <p>
                    « Que la Force soit avec vous ! »
                  </p>
                  <p>
                    🚀 La TEAM #BMTV.
                  </p>
                  <h3>
                    ► C’EST À VOUS :
                  </h3>
                  <div>
                    <ContactFormComponent />
                  </div>
                </div>
                <div className="col-md-4">
                  <img className="img-fluid pb-3" src="./assets/img/jeremy/jeremy_watier_contact.jpg" alt="" />
                  <div class="mt-4 text-center">
                    <a href={globalConstants.socialLinks.youtube}>
                      <i class="fab fa-youtube text-danger fa-2x me-3"></i>
                    </a>
                    <a href={globalConstants.socialLinks.facebook}>
                      <i class="fab fa-facebook text-primary fa-2x me-3"></i>
                    </a>
                    <a href={globalConstants.socialLinks.instagram}>
                      <i class="fab fa-instagram text-danger fa-2x me-3"></i>
                    </a>
                    <a href={globalConstants.socialLinks.twitter}>
                      <i class="fab fa-twitter text-info fa-2x me-3"></i>
                    </a>
                    <a href={globalConstants.socialLinks.tiktok}>
                      <i class="fab fa-tiktok text-dark fa-2x me-3"></i>
                    </a>
                    <a href={globalConstants.socialLinks.linkedin}>
                      <i class="fab fa-linkedin text-primary fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
