import React from 'react'

const styles = {
    backgroundAbout: {
        backgroundImage: 'url(assets/img/banners/bioBanner.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    }
}

export function BioHeaderComponent() {
  return (
    <div>
        <section style={ styles.backgroundAbout } class="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-quaternary overlay-show overlay-op-8 mb-0" >
            <div class="container pt-5">
                <div class="row">
                    <div class="col align-self-center p-static text-center mt-4">
                        <h1 className="text-12"><strong></strong></h1>
                        <span class="sub-title"></span>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
