import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Swal from 'sweetalert2'
import { useCart } from '../../shared/CartDataContext'
import { useUserData } from '../../shared/UserDataContext'
import { getData } from '../../shared/dataServices'
import { globalConstants } from '../../shared/globalConstants'


export default function VideoPlanComponent() {
    let business = 0;
    let mindset = 0;

    const { addItem } = useCart();
    const [newItem, setNewItem] = useState({});

    const [mindsetValue, setMindsetValue] = useState(false);
    const [businessValue, setBusinessValue] = useState(false);

    const [buyedMinset, setBuyedMinset] = useState(false);
    const [buyedBusiness, setBuyedBusiness] = useState(false);

    const { loggedIn, readToken } = useUserData();
    const token = readToken();


    useEffect(() => {
        async function fetchData() {
            const plan = await getUserPlan(token.nameid)
            console.log(plan);
            if (plan === 'Challenge Business') {
                setBuyedBusiness(true);
            } else if (plan === 'Challenge Mindset') {
                setBuyedMinset(true);
            } else if (plan === 'Full Challenge') {
                setBuyedBusiness(true);
                setBuyedMinset(true);
            }
        }
        fetchData()
    }, [])

    const getUserPlan = async (uid) => {
        let body = {
            llamado: 'user_data',
            var_texto: uid
        }
        console.log(body);
        const res = await getData(body).then((data) => {
            console.log(data);
            return data.plan
        }).catch((error) => {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur s\'est produite lors de la récupération des données utilisateur' + error,
            })
        })

        return res;

    }

    const handleMindset = () => {
        setMindsetValue(prevMindsetValue => {
            const newMindsetValue = !prevMindsetValue;
            const mindset = newMindsetValue ? 297 : 0;
            setTotals(mindset + (businessValue ? 297 : 0)); // Using the current value of businessValue
            return newMindsetValue;
        });
    }

    const handleBusiness = () => {
        setBusinessValue(prevBusinessValue => {
            const newBusinessValue = !prevBusinessValue;
            const business = newBusinessValue ? 297 : 0;
            setTotals(business + (mindsetValue ? 297 : 0)); // Using the current value of mindsetValue
            return newBusinessValue;
        });
    }




    const [totals, setTotals] = useState(business + mindset);

    return (
        <div>
            <div className=''>
                <div style={{ position: "relative", marginBottom: 40 }}>
                    <img src="./assets/img/logos/bizmind_logo.png" alt="" />
                </div>

                <table className="shop_table cart-totals mb-3">
                    <tbody>
                        <tr>
                            <td>
                                <strong className="d-block text-color-dark line-height-1 font-weight-semibold">Mindset Challenge </strong>
                            </td>
                            <td className="text-end align-top">
                                <span className="amount font-weight-medium text-color-grey">297 €</span>
                            </td>
                            <td>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input" id="chk_mindset" onClick={handleMindset} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-top-0">
                                <strong className="d-block text-color-dark line-height-1 font-weight-semibold">Business Challenge  </strong>
                            </td>
                            <td className="border-top-0 text-end align-top">
                                <span className="amount font-weight-medium text-color-grey">297 €</span>
                            </td>
                            <td>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input" id="chk_business" onClick={handleBusiness} />
                                </div>
                            </td>
                        </tr>
                        {
                            totals === 594 &&
                            <tr className="cart-subtotal">
                                <td className="border-top-0">
                                    <strong className="text-color-dark">Rabais</strong>
                                </td>
                                <td className="border-top-0 text-end">
                                    <strong><span className="amount font-weight-medium">25%</span></strong>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

                {
                    buyedMinset || buyedBusiness ?
                        <a className='btn btn-3d btn-dark w-100 btn-xl mt-4' href='video-player'>Accéder au contenu</a> :
                        <button
                            disabled={totals === 0}
                            className="btn btn-3d btn-primary w-100 btn-xl mt-4"
                            onClick={() => {
                                const itemName = businessValue && mindsetValue ? 'Full Challenge' : businessValue ? 'Challenge Business' : 'Challenge Mindset';
                                const itemValue = totals - (totals === 594 ? 594 * 0.25 : 0);
                                const productId = businessValue && mindsetValue ? globalConstants.products.full : businessValue ? globalConstants.products.business : globalConstants.products.mindset;
                                const newItem = {
                                    productId: productId,
                                    productName: itemName,
                                    value: itemValue,
                                    quantity: 1,
                                    state: 'Active'
                                };

                                setNewItem(newItem);
                                addItem(newItem);

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Produit ajouté au panier',
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: 'Aller payer',
                                    cancelButtonText: 'Continuer mes achats',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.href = '/checkout';
                                    }
                                });
                            }}
                        >
                            Ajouter au panier - {totals - (totals === 594 ? 594 * 0.25 : 0)} € <i className="fas fa-angle-right position-relative top-1 ms-1"></i>
                        </button>
                }
            </div>
        </div>
    )
}
