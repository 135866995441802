import React from 'react'

export function BsmtvTextComponent() {
    return (
        <div>
            <div className="container">
                <div className=" text-center mt-4">
                    <h1 className="font-weight-bold text-8 text-danger">
                        ✌️  À PROPOS DE #BMTV
                    </h1>
                    <h3 className="font-font-weight-medium">🚀Bienvenue à bord de la fusée #BMTV</h3>
                    <div className="row">
                        <div className="col-md-8 text-justify">
                            <p>
                                Chaque entreprise commence par une idée, un rêve façonné par l'ambition et la détermination d'un individu. Pour moi, cette idée a pris forme un jour, dans un élan de vision et de passion. Ainsi est née BMTV, bien plus qu'une simple société, mais une véritable incarnation de mes ambitions dans le monde des affaires et de la pensée positive.
                            </p>
                            <p>
                                BMTV n'est pas seulement une plateforme de e-commerce, c'est un reflet de mon parcours en tant que jeune entrepreneur, un voyage marqué par le désir ardent d'aider les autres à naviguer dans le monde complexe du business et du développement personnel. Avec une approche simple et directe, notre société se veut être un phare pour ceux qui cherchent à comprendre les rouages du commerce en ligne et à cultiver un état d'esprit propice à la réussite.
                            </p>
                            <p>
                                Au cœur de BMTV réside un engagement profond à partager les connaissances et les enseignements que j'ai acquis au fil des années. Chaque conseil, chaque leçon transmise est le fruit d'une expérience vécue, d'erreurs commises et de succès remportés. Je crois fermement que l'épanouissement personnel et professionnel vont de pair, et c'est pourquoi je m’efforce d'offrir des conseils pratiques et indispensables à ceux qui aspirent à une vie plus enrichissante.
                            </p>
                            <p>
                                Que vous soyez un novice dans le monde des affaires ou un entrepreneur chevronné en quête de nouvelles perspectives, BMTV est là pour vous accompagner dans votre parcours. Rejoignez-nous, et ensemble, façonnons un avenir où le succès et l'épanouissement vont de pair.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <img className="img-fluid rounded" src="assets/img/office/bsmtv_image.jpg" alt="" />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-7">
                            <div className="float-end appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="200">
                                <blockquote className="blockquote-primary blockquote-reverse">
                                    <p>
                                        “ Tout ce que le mental de l’homme peut concevoir et parvenir à croire, alors il peut aussi le réaliser. ”
                                    </p>
                                    <footer className="blockquote-footer">Napoléon Hill – Auteur, Reporter et Conférencier</footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="col-md-5 pb-4">
                            <img src="assets/img/office/napoleon_hill.jpeg" className="img-fluid rounded-circle" alt="napoleon hill" />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <a href='/jeremy-watier' className="btn btn-gradient btn-xl">QUI EST JEREMY WATIER ? (LE FONDATEUR DE #BMTV) </a>
                    </div>
                    <div className="col-md-6">
                        <a href='/mes-programmes-videos' className="btn btn-gradient btn-xl">DÉCOUVRIR TOUS NOS PROGRAMMES VIDÉOS</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
