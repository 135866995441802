import React from 'react'
import { globalConstants } from '../shared/globalConstants'

export function PromobarComponent() {
    return (
        <div className="header-top header-top-default border-bottom-0 bg-color-dark">
            <div className="container">
                <div className="header-row py-2">
                    <div className="header-column justify-content-start">
                        <div className="header-row">
                            <nav className="header-nav-top">
                                <ul className="nav nav-pills text-uppercase text-2">

                                    <li className="nav-item nav-item-anim-icon">
                                        <a className="nav-link ps-0 text-light opacity-7" href="/mes-programmes-videos">
                                            <i className="fas fa-angle-right"></i> Regarder le programme
                                        </a>
                                    </li>
                                    {/*
                                  <li className="nav-item nav-item-anim-icon">
                                    <a className="nav-link text-light opacity-7 pe-0" href="/">
                                        <i className="fas fa-angle-right"></i> Contact Us
                                    </a>
                                    </li> 
                                */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="header-column justify-content-end">
                        <div className="header-row">
                            <ul
                                className="header-social-icons social-icons d-none d-sm-block social-icons-clean social-icons-icon-light">
                                <li className="social-icons-facebook"><a href={globalConstants.socialLinks.facebook} target="_blank" rel="noreferrer" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                <li className="social-icons-twitter"><a href={globalConstants.socialLinks.twitter} target="_blank" rel="noreferrer" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                <li className="social-icons-linkedin"><a href={globalConstants.socialLinks.linkedin} target="_blank" rel="noreferrer" title="Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
