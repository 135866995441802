import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { ContactHeaderComponent } from '../components/Contact/ContactHeaderComponent'
import { ContactComponent } from '../components/Contact/ContactComponent'
import { FooterComponent } from '../components/FooterComponent'


export function ContactPage() {
  return (
    <div>
    <HeaderComponent />
      <div role="main" class="main">
        <ContactHeaderComponent />
        <ContactComponent />
        <FooterComponent />
      </div>
    </div>
  )
}
