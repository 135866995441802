import React from 'react'

export function FacebookSocialComponent() {
    return (
        <div className="mt-4 text-center">
            <div className="text-center">
                <h2 className="font-weight-bold text-6 text-danger">LA PAGE FACEBOOK #BMTV</h2>
            </div>
            <div>
                <p>
                    toutes les dernières alertes et actualités relatives à « Biz Mind TV », des posts inspirants, nos dernières vidéos.
                </p>
                <p>
                    Vous aurez aussi la possibilité d’assister à nos lives Facebook mensuels réalisés directement depuis la page.
                </p>
                <p>
                    Vous pourrez enfin nous laisser vos commentaires, partager vos retours d’expériences, donner vos avis ou encore nous contacter en privé via Messenger.
                </p>
            </div>
            <img src="./assets/img/social/facebook.jpg" className="img-fluid" alt="" />
        </div>
    )
}
