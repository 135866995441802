import React from 'react'

const styles = {
    backgroundAbout: {
        backgroundImage: 'url(assets/img/banners/aboutBanner.jpg)',
        backgroundSize: 'cover',
    }
}

export function AboutHeaderComponent() {
  return (
    <section style={ styles.backgroundAbout } class="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-quaternary overlay-show overlay-op-8 mb-0" >
        <div class="container pt-5">
            <div class="row">
                <div class="col align-self-center p-static text-center mt-4">
                    <h1 className="text-12"><strong>Jeremy Watier</strong></h1>
                    <span class="sub-title">Bonjour, je suis quelqu'un qui a connu de grands succès, tant sur le plan personnel que intellectuel, malgré quelques revers temporaires dans le passé.</span>
                </div>
            </div>
        </div>
    </section>
  )
}
