import React from 'react'

export function AnecdotesComponent() {
  return (
    <div className="bg-color-light-scale-1 mt-4">
    <div className="container py-4">
        <div className=" text-center mt-4">
            <h1 className="font-weight-bold text-8 text-danger">
                Quelques anecdotes au sujet de Jeremy Watier
            </h1>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div class="card border-radius-0 bg-color-light border-0 box-shadow-1">
                    <div class="card-body">
                        <div class="card-title mb-4">
                            <h4 className="card-title mb-1 text-4 font-weight-bold">Anecdote sur le business:</h4>
                        </div>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> Dès mon plus jeune âge, j'ai été fasciné par le monde du business en ligne, éveillant ainsi ma curiosité précoce pour
                            les opportunités offertes par internet.
                        </p>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> À seulement 16 ans, j'ai investi dans ma première formation e-commerce, déclenchant ainsi une passion dévorante pour
                            l'apprentissage continu et l'acquisition de nouvelles compétences dans ce domaine.
                        </p>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> Depuis lors, ma détermination à partager les bienfaits du business en ligne n'a jamais faibli, guidée par la conviction
                            que cette voie peut offrir des opportunités sans précédent à ceux qui osent s'y aventurer.
                        </p>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> Grâce à une compréhension approfondie des algorithmes des réseaux sociaux, j'ai renforcé ma volonté de démystifier ces
                            plateformes et d'aider les autres à en tirer pleinement parti, convaincu que le partage de cette connaissance peut
                            transformer des vies.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div class="card border-radius-0 bg-color-light border-0 box-shadow-1">
                    <div class="card-body">
                        <h4 className="card-title mb-1 text-4 font-weight-bold mb-3">Anecdote la mindset</h4>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> Mon parcours dans le mindset a été enrichi par une diversité de sources, incluant des vidéos YouTube, des audios
                            inspirants.
                        </p>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> Les paroles percutantes des chansons de rap ont façonné ma perspective sur la vie, renforçant ma capacité à comprendre
                            la complexités du monde qui m'entoure.
                        </p>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> La musique a été une source constante d'inspiration pour moi, m'aidant à apprécier les différentes facettes de
                            l'expérience humaine et à développer une approche holistique du mindset.
                        </p>
                        <p class="mb-3">
                            <i class="fa fa-check me-3" aria-hidden="true"></i> Mes explorations dans le monde du mindset m'ont conduit à découvrir une multitude de traditions spirituelles et
                            philosophiques, dont une initiation auprès de sociétés secrètes, offrant des perspectives uniques sur la réalité et mon
                            propre chemin spirituel.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
