import React from 'react'
import { HeaderComponent } from '../components/HeaderComponent'
import { FooterComponent } from '../components/FooterComponent'
import { SocialHeaderComponent } from '../components/Social/SocialHeaderComponent'
import { SocialComponent } from '../components/Social/SocialComponent'
import { YoutubeSocialComponent } from '../components/Social/YoutubeSocialComponent'
import { FacebookSocialComponent } from '../components/Social/FacebookSocialComponent'
import { InstagramSocialComponent } from '../components/Social/InstagramSocialComponent'

export function SocialPage() {
  return (
    <div>
      <HeaderComponent />
      <div role="main" class="main">
        <SocialHeaderComponent />
        <SocialComponent />
        <YoutubeSocialComponent />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <FacebookSocialComponent />
            </div>
            <div className="col-md-6">
              <InstagramSocialComponent />
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  )
}
