import React, { useState } from 'react'
import { HeaderComponent } from '../../components/HeaderComponent'
import { FooterComponent } from '../../components/FooterComponent'
import { useCart } from '../../shared/CartDataContext'
import { useUserData } from '../../shared/UserDataContext'
import { getPayment, updateData } from '../../shared/dataServices'

export default function CheckoutPage() {
    const { loggedIn, readToken } = useUserData();
    const { cart, getTotalValue, clearCart } = useCart();
    const token = readToken();

    const [checkout, setCheckout] = useState({
        "cartItems": [
            {
                "productId": "1",
                "productName": "Challenge BizMind",
                "value": 297,
                "quantity": 1,
                "state": "Active"
            }
        ],
        "userId": token ? token.uniqueid : '0',
        "userName": token ? token.unique_name : '',
        "userEmail": token ? token.email : '',
        "userPhone": "",
        "orderNotes": ""
    })

    const handleChanges = (e) => {
        setCheckout({
            ...checkout,
            [e.target.name]: e.target.value
        })
    }

    const registerCheckout = async (id) => {
        let body = {
            StoreProcedure: 'SP_AdPayments',
            Valores: [{
                user_payment_user_id: token ? token.uniqueid : '0',
                user_payment_email: checkout.userEmail,
                user_payment_full_name: checkout.userId,
                user_payment_phone: checkout.userPhone,
                user_payment_amount: getTotalValue(),
                user_payment_status: 'Pending',
                user_payment_date: new Date().toISOString(),
                user_payment_notes: checkout.orderNotes,
                user_payment_ref: id
            }]
        };

        const res = await updateData(body).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }

    const sendPayment = async () => {
        if (!loggedIn) { return; }
        let body = {
            priceId: cart.cartItems[0].productId,
            qty: 1
        };
        const res = await getPayment(body).then((response) => {
            //clearCart();
            registerCheckout(response.id);
            sessionStorage.setItem('productName', cart.cartItems[0].productName);
            if (response.url) {
                window.location.href = response.url;
            }
        }).catch((error) => {
            console.log(error);
        })

        console.log(res);
    }

    return (
        <div>
            <HeaderComponent />
            <div role='main' className="main bg-color-grey-scale-1">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ul className="breadcrumb breadcrumb-dividers-no-opacity font-weight-bold text-6 justify-content-center my-5">
                                <li className="text-transform-none me-2">
                                    <a href="/Bizmind/mes-programmes-videos" className="text-decoration-none text-color-dark text-color-hover-primary">Mes programmes vidéos</a>
                                </li>
                                <li className="text-transform-none text-color-dark me-2">
                                    <a href="/checkout" className="text-decoration-none text-color-primary">Passer à la caisse</a>
                                </li>
                                {/* <li className="text-transform-none text-color-grey-lighten">
                                    <a href="/Bizmind/mes-programmes-videos" className="text-decoration-none text-color-grey-lighten text-color-hover-primary">Commande terminée</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {
                        !loggedIn &&
                        <div className="row">
                            <div className="col">
                                <p className="mb-1">Client existant? <a href="/" data-bs-toggle="modal" data-bs-target="#loginModal" className="text-color-dark text-color-hover-primary text-uppercase text-decoration-none font-weight-bold">Connexion</a></p>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col">
                            <p>Avez-vous un coupon? <a href="/" className="text-color-dark text-color-hover-primary text-uppercase text-decoration-none font-weight-bold" data-bs-toggle="collapse" data-bs-target=".coupon-form-wrapper">Entrez votre code</a></p>
                        </div>
                    </div>

                    <div className="row coupon-form-wrapper collapse mb-5">
                        <div className="col">
                            <div className="card border-width-3 border-radius-0 border-color-hover-dark">
                                <div className="card-body">
                                    <form method="post" action="/">
                                        <div className="d-flex align-items-center">
                                            <input type="text" className="form-control h-auto border-radius-0 line-height-1 py-3" name="couponCode" placeholder="Code de coupon" required />
                                            <button type="submit" className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 ws-nowrap btn-px-4 py-3 ms-2">Appliquer le coupon</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="needs-validation mb-4" method="post" action="">
                        <div className="row">
                            <div className="col-lg-7 mb-4 mb-lg-0 bg-light p-4">

                                <h2 className="text-color-dark font-weight-bold text-5-5 mb-3">Détails de facturation</h2>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label className="form-label">Nom complet <span className="text-color-danger">*</span></label>
                                        <input type="text" className="form-control h-auto py-2" name="userName" value={checkout.userName} onChange={handleChanges} required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col">
                                        <label className="form-label">Téléphone <span className="text-color-danger">*</span></label>
                                        <input type="text" className="form-control h-auto py-2" name="userPhone" value={checkout.userPhone} onChange={handleChanges} required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col">
                                        <label className="form-label">Adresse e-mail <span className="text-color-danger">*</span></label>
                                        <input type="email" className="form-control h-auto py-2" name="userEmail" value={checkout.userEmail} onChange={handleChanges} required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col">
                                        <label className="form-label">Notes de commande</label>
                                        <textarea className="form-control h-auto py-2" name="orderNotes" value={checkout.orderNotes} onChange={handleChanges} rows="5" placeholder="Notes sur votre commande, par ex. notes spéciales pour la livraison"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 position-relative">
                                <div className="card border-width-3 border-radius-0 border-color-hover-dark" data-plugin-sticky data-plugin-options="{'minWidth': 991, 'containerSelector': '.row', 'padding': {'top': 85}}">
                                    <div className="card-body">
                                        <h4 className="font-weight-bold text-uppercase text-4 mb-3">Votre commande</h4>
                                        <table className="shop_table cart-totals mb-3">
                                            <tbody>
                                                {
                                                    cart.cartItems.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <strong className="d-block text-color-dark line-height-1 font-weight-semibold">{item.productName}</strong>
                                                                    <span className="text-1">{item.quantity} Vidéos</span>
                                                                </td>
                                                                <td className="text-end align-top">
                                                                    <span className="amount font-weight-medium text-color-grey">{item.value === 297 ? 297 : 594} €</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                                <tr className="cart-subtotal">
                                                    <td className="border-top-0">
                                                        <strong className="text-color-dark">Sous-total</strong>
                                                    </td>
                                                    <td className="border-top-0 text-end">
                                                        <strong><span className="amount font-weight-medium">{getTotalValue() === 297 ? 297 : 594} €</span></strong>
                                                    </td>
                                                </tr>
                                                {
                                                    getTotalValue() > 300 &&
                                                    <tr className="shipping">
                                                        <td colspan="2">
                                                            <strong className="d-block text-color-dark mb-2">Réductions</strong>

                                                            <div className="d-flex flex-column">
                                                                <label className="d-flex align-items-center text-color-grey mb-0" for="shipping_method1">
                                                                    CODE DE RÉDUCTION: BIZMIND <strong className='ms-4 fs-5'>-25%</strong>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                <tr className="total">
                                                    <td>
                                                        <strong className="text-color-dark text-3-5">Total</strong>
                                                    </td>
                                                    <td className="text-end">
                                                        <strong className="text-color-dark"><span className="amount text-color-dark text-5">{getTotalValue()} €</span></strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        Vos données personnelles seront utilisées pour traiter votre commande, soutenir votre expérience sur ce site Web, et à d'autres fins décrites dans notre politique de confidentialité.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button disabled={getTotalValue() === 0} onClick={sendPayment} data-bs-toggle={!loggedIn && 'modal'} data-bs-target="#loginModal" className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3">
                                            {
                                                loggedIn ? 'Procéder au paiement' : 'Connectez-vous pour continuer'
                                            }
                                            <i className="fas fa-arrow-right ms-2"></i>
                                        </button>
                                        <div className='text-center mt-2'>
                                            <a href="/mes-programmes-videos" class="btn btn-link-effect-4 bg-transparent text-primary bg-color-before-primary border-0 p-0 me-3">Retour au magasin</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent />
            </div>

        </div>
    )
}
