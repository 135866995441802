// CartContext.js
import React, { createContext, useContext, useReducer, useEffect } from 'react';

// Estado inicial del carrito
const initialState = {
    state: 'Local',
    cartItems: [],
    userId: '',
    userName: '',
    userEmail: '',
    cityState: '',
    cityName: ''
};

// Reducer para manejar acciones del carrito
const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ITEM':
            state.cartItems = []
            const existingItemIndex = state.cartItems.findIndex(item => item.productId === action.payload.productId && item.state === 'Active');
            if (existingItemIndex >= 0) {
                const updatedCartItems = state.cartItems.map((item, index) =>
                    index === existingItemIndex ? { ...item, quantity: item.quantity + action.payload.quantity } : item
                );
                return { ...state, cartItems: updatedCartItems };
            } else {
                return { ...state, cartItems: [...state.cartItems, action.payload] };
            }
        case 'REMOVE_ITEM':
            return { ...state, cartItems: state.cartItems.filter((_, index) => index !== action.payload) };
        case 'UPDATE_ITEM':
            const updatedItems = state.cartItems.map((item, index) =>
                index === action.payload.index ? { ...item, quantity: action.payload.quantity } : item
            );
            return { ...state, cartItems: updatedItems };
        case 'SET_CART':
            return action.payload;
        case 'CLEAR_CART':
            return initialState;
        default:
            return state;
    }
};

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            dispatch({ type: 'SET_CART', payload: JSON.parse(storedCart) });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addItem = (item) => {
        dispatch({ type: 'ADD_ITEM', payload: item });
    };

    const removeItem = (index) => {
        dispatch({ type: 'REMOVE_ITEM', payload: index });
    };

    const updateItem = (index, quantity) => {
        dispatch({ type: 'UPDATE_ITEM', payload: { index, quantity } });
    };

    const getItemsCount = () => {
        return cart.cartItems.filter(item => item.state === 'Active').length;
    };

    const getTotalValue = () => {
        return cart.cartItems.filter(item => item.state === 'Active').reduce((acc, cur) => acc + cur.value * cur.quantity, 0);
    };

    const clearCart = () => {
        dispatch({ type: 'CLEAR_CART' });
    };

    return (
        <CartContext.Provider value={{ cart, addItem, removeItem, updateItem, getItemsCount, getTotalValue, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
