import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { useCart } from '../../shared/CartDataContext'
import Swal from 'sweetalert2'
import VideoMindsetComponent from './VideoMindsetComponent';
import VideoBussinessComponent from './VideoBussinessComponent';
import VideoPlanComponent from './VideoPlanComponent';


export default function VideosComponent() {

    const { addItem } = useCart();

    const [newItem, setNewItem] = useState({
        productId: '',
        productName: '',
        value: 0,
        quantity: 1,
        image: '',
        state: 'Registrado'
    });

    const handleAddItem = () => {

    };

    return (
        <div className="bg-color-light-scale-1">
            <div className="container">
                <div className=" pt-5">
                    <div className='text-center'>
                        <h1 className="font-weight-bold text-8 text-danger">
                            Découvrez mon Challenge BizMind
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                Découvrez notre Formation Vidéo Challenge Payant : Devenez un Expert en Business en Ligne et en Mindset.
                            </p>
                            <div className="mt-4">
                                <p>
                                    Vous rêvez de lancer votre propre business en ligne mais vous ne savez pas par où commencer ? Ou peut-être avez-vous déjà fait vos premiers pas mais cherchez-vous à optimiser vos résultats ? Notre formation vidéo challenge est conçue pour vous guider, étape par étape, vers la réussite. Ce programme complet vous apporte les connaissances et les outils nécessaires pour exceller dans le domaine des infoproduits et adopter un mindset de succès.
                                </p>
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    {/* Begin first program */}
                                    <div>
                                        <div className="toggle toggle-minimal toggle-primary row">
                                            <div className='toggle active'>
                                                <div className="text-center">
                                                    <div className='fw-bold text-primary'>
                                                        <i className='fa fa-video me-1'></i> Formation Vidéo Challenge : Devenez Entrepreneur et Vivez de Votre Business en Ligne
                                                    </div>
                                                    <a href="/" className='btn btn-sm btn-secondary btn-with-arrow mb-2' data-bs-toggle="collapse" data-bs-target=".coupon-form-wrapper">
                                                        Lire plus
                                                        <span>
                                                            <i className="fas fa-chevron-down"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row coupon-form-wrapper collapse mb-5">
                                            <div className="col">
                                                <div className="card border-width-3 border-radius-0 border-color-hover-dark">
                                                    <div className="card-body">
                                                        <VideoBussinessComponent />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End first program */}
                                    {/* Begin Mid */}
                                    <div className="mt-4 row">
                                        <p>
                                            En vous inscrivant à notre formation, vous apprendrez non seulement à créer des infoproduits de qualité, mais aussi à les commercialiser efficacement, à développer une audience fidèle et à maximiser vos revenus. Ne manquez pas cette opportunité de transformer votre passion et vos connaissances en une source de revenus lucrative.
                                        </p>

                                        <h4 className='fw-bold'>
                                            <i className="fa fa-store me-3"></i> Comment la Vente d’Infoproduits Peut Vous Aider ?
                                        </h4>
                                        <p>
                                            La vente d'infoproduits est une méthode efficace pour générer des revenus en ligne. En partageant vos connaissances et votre expertise sous forme de guides, de cours vidéo, ou de webinaires, vous pouvez atteindre un large public et offrir des solutions précieuses à leurs problèmes. Cette approche présente plusieurs avantages :
                                        </p>

                                    </div>
                                    {/* End mid */}

                                    {/* Begin second program */}

                                    <div className="toggle toggle-minimal toggle-primary row">
                                        <div className='toggle active'>
                                            <div className="text-center">
                                                <div className='fw-bold text-secondary'>
                                                    <i className='fa fa-video me-1'></i> Formation Vidéo Challenge : Développez un Mindset de Succès pour l'Entreprenariat
                                                </div>
                                                <a href="/" className='btn btn-sm btn-primary btn-with-arrow mb-2' data-bs-toggle="collapse" data-bs-target=".second-program">
                                                    Lire plus
                                                    <span>
                                                        <i className="fas fa-chevron-down"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row second-program collapse mb-5">
                                        <div className="col">
                                            <div className="card border-width-3 border-radius-0 border-color-hover-dark">
                                                <div className="card-body">
                                                    <VideoMindsetComponent />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* End second program */}

                                    <ul className="list list-icons list-primary list-borders mt-4">
                                        <li><i className="fas fa-check"></i> <strong>Scalabilité :</strong>  Une fois votre infoproduit créé, vous pouvez le vendre à un nombre illimité de personnes sans coûts supplémentaires de production.</li>
                                        <li><i className="fas fa-check"></i> <strong>Flexibilité :</strong>  Vous pouvez travailler de n'importe où et ajuster votre emploi du temps selon vos besoins, ce qui est idéal pour un style de vie indépendant.</li>
                                        <li><i className="fas fa-check"></i> <strong>Autorité et Influence :</strong>  En partageant votre expertise, vous renforcez votre crédibilité dans votre domaine et vous établissez comme un leader d'opinion.</li>
                                        <li><i className="fas fa-check"></i> <strong> Passivité des revenus :</strong> Les infoproduits permettent de générer des revenus passifs, car vous pouvez continuer à vendre vos produits sans intervention continue.  </li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <VideoPlanComponent />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
