//import React from 'react'
import React, { useState } from 'react';
import { sendJoin } from '../../../shared/dataServices';
import Swal from 'sweetalert2'


export function SliderComponent() {

    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });
    // const [success, setSuccess] = useState(false);
    // const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // setError(false);
        // setSuccess(false);
        let body = JSON.stringify(formData)




        const response = await sendJoin(body)
            .then((data) => {
                setLoading(false)
                // setError(false)
                // setSuccess(true)
                Swal.fire({
                    title: 'Message envoyé',
                    icon: 'success',
                    text: 'Votre message a été envoyé avec succès, nous vous contacterons bientôt'
                })
                return data
            }).catch((error) => {
                Swal.fire({
                    title: 'Votre message na pas été envoyé',
                    icon: 'error',
                    text: 'nous avons eu un problème' + error
                })
                console.log(error)
            })

        console.log(response)
    };



    return (
        <section id="home" className="border-0 bg-dark">
            <div className="container text-light pt-5">
                <div className="row min-height-screen align-items-center pt-5">
                    <div className="col-lg-7 pt-5 pt-lg-0">
                        <div className="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="0">
                            <h1 className="text-warning font-weight-bold">Bienvenue à bord de la fusée  <em className="p-relative z-index-1 fst-normal">#BMTV <span className="appear-animation" data-appear-animation="highlightScribble1"><svg className="highlight-scribble-1" viewBox="0 0 760 60" preserveAspectRatio="none"><path d="M19,49C75.19,30.63,152,21,317.26,17.27c55.43-.41,198.33-2.08,407.85,12.53" stroke="#0088cc" pathLength="1" strokeWidth="14" fill="none"></path></svg></span></em></h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-9 pe-xl-5">
                                <p className="text-5 text-light line-height-6 font-weight-regular" data-plugin-animated-letters data-plugin-options="{'startDelay': 1000, 'minWindowWidth': 0, 'animationName': 'typeWriter', 'animationSpeed': 10}">Bonjour ! Je suis Jeremy Watier, je vous guide dans l'univers du business en ligne et
                                    du développement personnel.</p>
                                <p>
                                    À travers mon programme <a href="/mes-programmes-videos">Challenge BizMind</a> , je vous accompagne sur le chemin de la réussite et de l'épanouissement.
                                </p>
                            </div>
                        </div>

                        <form className="row" onSubmit={handleSubmit}>
                            <div className="row">

                                <div className="col-4">

                                    <input
                                        type="text"
                                        value={formData.name}
                                        onChange={handleChange}
                                        data-msg-required="Please enter your name."
                                        maxLength="100"
                                        className="form-control text-3 h-auto py-2"
                                        name="name"
                                        placeholder='Votre nom'
                                        required
                                    />
                                </div>

                                <div className="col-4">
                                    <input
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        data-msg-required="Please enter your email."
                                        maxLength="100"
                                        className="form-control text-3 h-auto py-2"
                                        name="email"
                                        placeholder='E-mail'
                                        required
                                    />
                                </div>
                                <div className="col-4">

                                    <button type="submit" className="btn btn-gradient" disabled={loading}>
                                        {loading ? 'Loading...' : 'Rejoindre'}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-5 pt-5 pt-lg-0 text-lg-end">

                        <div className="p-relative">
                            <span className="d-block img-thumbnail rounded-circle">
                                <img src="assets/img/jeremy/jeremy_watier.jpg" className="img-fluid rounded-circle p-relative z-index-1 appear-animation" alt="" data-appear-animation="fadeIn" data-appear-animation-delay="200" />
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default SliderComponent;